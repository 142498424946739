<template>
  <div class="container-fluid bgcustom pt-3 pb-5 pe-4 ps-4">
    <div class="card shadow rounded-5 hborder pe-0 ps-1 pt-0 pb-0">
      <div class="card-body pt-0">
        <div class="row justify-content-center pt-2">
          <div class="col col-sm-6 text-center">
            <div v-if="hasError" class="alert alert-danger p-2"> {{ message }} </div>
          </div>
        </div>
        <!--begin:breadcrumb-->
        <div class="row pt-2 pb-3">
          <div class="col ps-3">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item">
                  <router-link to="/"><img src="/assets/icons/back-arrow.svg" alt="icon"
                      class="icon-class-bg" /></router-link>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <!--end:breadcrumb-->
        <div class="row justify-content-center">
          <div class="col col-sm-6 text-center">
            <div v-if="uploadError" class="alert alert-danger p-1">{{ message }}</div>
            <div v-if="hasError" class="alert alert-danger p-1">{{ message }}</div>
          </div>
        </div>
        <div class="row padding-t align-items-center">
          <div class="col-6 col-sm-4 d-flex flex-column">
            <label class="form-label font-14 mb-1">Choose your brand</label>
            <select class="form-select font-14" v-model="brand">
              <option value="0">Select Brand Name</option>
              <option v-for="(val, index) in row.brands" :key="index" :value="val.id">{{ val.name }}</option>
            </select>
          </div>
          <div class="col-6 col-sm-6 text-end">
            <loader class="w-50" v-if="uploadloader" />
            <button v-else type="button" class="btn bg-button px-4" @click="uploadImage()">Upload</button>
          </div>
        </div>
        <div class="row pt-4">
          <div class="col-12 col-sm-12">
            <div style="position: relative;">
              <!-- Centered Text and Icon above DropZone -->
              <div class="d-flex justify-content-center align-items-center pb-2">
                <div class="form-text d-flex align-items-center">
                  <span><i class="fa-solid fa-cloud-arrow-up font-26 pe-2"></i> Drag a image here or click to choose a
                    file.</span>
                </div>
              </div>
              <!-- Full width DropZone -->
              <DropZone @addedFile="onFileAdd" message="Upload your files here" :maxFiles="Number(3)"
                :maxFileSize="10000000" url="http://localhost:5000/item" :uploadOnDrop="false" :multipleUpload="true"
                :parallelUpload="3" class="w-100" style="min-height: 200px;" />
            </div>
          </div>
        </div>
        <div class="row p-2 font-14 add-image">
          <div class="col-auto col-sm-3 font-h text-center">
            <span>RECENT UPLOADS</span>
            <div class="line"></div>
          </div>
          <div class="col-auto text-center col-sm-3">
            <!-- <button class="btn btn btn-filter">Filter</button> -->
            <div class="dropdown">
              <button class="btn btn btn-filter dropdown-toggle" type="button" id="dropdownMenuButton1"
                data-bs-toggle="dropdown" aria-expanded="false">
                Filter
              </button>
              <ul class="dropdown-menu p-2" aria-labelledby="dropdownMenuButton1">
                <li>
                  <label for="">Brand Name</label>
                  <select name="" v-model="brandFilter" class="form-select" id="">
                    <option value="">Not selected</option>
                    <option v-for="(brand, index) in row.brands" :key="index" :value="brand.id">{{ brand.name }}
                    </option>
                  </select>
                </li>
                <li>
                  <label for="">Date</label>
                  <select name="" class="form-select" v-model="date" id="">
                    <option value="">Not selected</option>
                    <option value="create_at">Ascending</option>
                    <option value="-create_at">Descending</option>
                  </select>
                </li>
                <li class="py-3 text-center">
                  <button type="button" @click.prevent="filterGallery()" class="btn btn-primary px-4">Filter</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- sawaf -->
        <div class="row" v-if="loadLibrary">
          <div class="col text-center">
            <loadersoda />
          </div>
        </div>
        <div class="library-container">
          <!-- Image Library Section with Scrollable Area -->
          <div class="row pt-3 library-h" ref="scrollContainer" @scroll="handleScroll">
            <div class="col-3 col-sm-3 p-2" v-for="(val, index) in images" :key="index">
              <div class="image-container">
                <img class="img-fluid" :src="BASE_URL + val.image" alt="" />
              </div>
            </div>
            <!-- Load More Indicator -->
            <div v-if="loadMore" class="text-center py-4">
              <loadersoda />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import { DropZone } from 'dropzone-vue';

export default defineComponent({
  name: "library",
  data() {
    return {
      uploadError: false,
      hasError: false,
      uploadloader: false,
      message: "",
      row: {},
      images: [],
      brand: 0,
      recentImage: 0,
      brandFilter: 0,
      date: "",
      loadLibrary: false,
      filterLoading: false,
      nextPage: null,
      loadMore: false,
      autoLoadAttempts: 0,
    }
  },
  computed: {
    ...mapGetters(['BASE_URL']),
  },
  components: {
    DropZone,
  },
  methods: {
    getData() {
      this.$http.get('/api/subscriptions/brands/').then(res => {
        // console.log(res);
        this.row = res.data;
        // Automatically set the first brand as the selected value
        if (this.row.brands.length > 0) {
          this.brand = this.row.brands[0].id;  // Set the brand to the first value
        }
      }).catch(err => {
        if (err.response.data.error) {
          this.message = err.response.data.error;
        }
        if (err.response.data.errors) {
          let key = Object.keys(err.response.data.errors)[0];
          this.message = err.response.data.errors[key][0];
        }
        this.hasError = true;
        this.loadLibrary = false;
        window.scroll(0, 0);
      })
    },
    uploadImage() {
      const formData = new FormData()
      formData.append("image", this.image);
      formData.append("brand", this.brand);
      if (!this.brand) {
        this.message = "You must select brand";
        this.uploadError = true;
        return false;
      }
      if (!this.image) {
        this.message = "You must choose the image";
        this.uploadError = true;
        return false;
      }
      this.uploadloader = true;
      this.$http.post("/api/templates/images/upload/", formData).then(res => {
        // console.log(res);
        this.uploadError = false;
        this.image = "";
        this.uploadloader = false;
        document.getElementsByClassName('gg-close')[0].click()
        this.getImagelist();
      }).catch(err => {
        // console.log(err);
        if (err.response.data.error) {
          this.message = err.response.data.error;
          this.cdesignErorr = true;
        }
        if (err.response.data.errors) {
          let key = Object.keys(err.response.data.errors)[0];
          this.message = err.response.data.errors[key][0];
        }
        this.uploadError = true;
        this.uploadloader = false;
        window.scroll(0, 0);
      })
    },
    // Fetch initial set of images (8 images)
    getImagelist() {
      this.loadingLibrary = true;
      this.autoLoadAttempts = 0; // Reset auto-load attempts
      this.$http
        .get("/api/templates/images/", { params: { limit: 8 } })
        .then((res) => {
          this.images = res.data.results;
          this.nextPage = res.data.next; // Capture the next page URL
          this.loadingLibrary = false;
          this.$nextTick(() => {
            this.checkIfMoreImagesNeeded();
          });
        })
        .catch((err) => {
          if (err.response.data.error) {
            this.message = err.response.data.error;
          }
          if (err.response.data.errors) {
            let key = Object.keys(err.response.data.errors)[0];
            this.message = err.response.data.errors[key][0];
          }
          this.loadingLibrary = false;
        });
    },
    // Fetch more images when scrolling
    loadMoreImages() {
      if (this.nextPage) {
        this.loadMore = true;
        this.$http
          .get(this.nextPage)
          .then((res) => {
            this.images.push(...res.data.results); // Append new images
            this.nextPage = res.data.next; // Update the next page URL
            this.loadMore = false;
            this.$nextTick(() => {
              this.checkIfMoreImagesNeeded();
            });
          })
          .catch((err) => {
            if (err.response.data.error) {
              this.message = err.response.data.error;
            }
            if (err.response.data.errors) {
              let key = Object.keys(err.response.data.errors)[0];
              this.message = err.response.data.errors[key][0];
            }
            this.loadMore = false;
          });
      }
    },
    checkIfMoreImagesNeeded() {
      const scrollContainer = this.$refs.scrollContainer;
      // Check if container is scrollable
      if (
        scrollContainer.scrollHeight <= scrollContainer.clientHeight &&
        this.nextPage &&
        this.autoLoadAttempts < 5 // Limit to prevent infinite loading
      ) {
        this.autoLoadAttempts++;
        this.loadMoreImages();
      }
    },

    // Handle scroll event on the library-h div
    handleScroll() {
      const scrollContainer = this.$refs.scrollContainer;
      const scrollPosition = scrollContainer.scrollTop + scrollContainer.clientHeight;
      const bottomPosition = scrollContainer.scrollHeight;
      if (scrollPosition >= bottomPosition - 10 && this.nextPage) {
        this.loadMoreImages();
      }
    },
    onFileAdd(e) {
      this.image = e.file;
      this.uploadImage();
    },
    sortImages() {
      if (this.date === 'create_at') {
        this.images.sort((a, b) => new Date(a.create_at) - new Date(b.create_at));
      } else if (this.date === '-create_at') {
        this.images.sort((a, b) => new Date(b.create_at) - new Date(a.create_at));
      }
    },
    filterGallery() {
      this.filterLoading = true;
      this.autoLoadAttempts = 0; // Reset auto-load attempts
      let params = {};
      params.limit = 8;
      if (this.brandFilter) {
        params.brand = this.brandFilter;
      }
      this.$http
        .get("/api/templates/images/", { params })
        .then((res) => {
          this.images = res.data.results;
          this.nextPage = res.data.next;
          this.filterLoading = false;
          this.sortImages(); // Apply Frontend-side sorting
          this.$nextTick(() => {
            this.checkIfMoreImagesNeeded();
          });
        })
        .catch(err => {
          if (err.response.data.error) {
            this.message = err.response.data.error;
          }
          if (err.response.data.errors) {
            let key = Object.keys(err.response.data.errors)[0];
            this.message = err.response.data.errors[key][0];
          }
          this.hasError = true;
          this.filterLoading = false;
          window.scroll(0, 0);
        });
    },
  },
  created() {
    this.getData();
    this.getImagelist(); // Initial load of images
  },
  unmounted() {
    // Remove scroll event listener when component is destroyed
    window.removeEventListener("scroll", this.handleScroll);
  },
});
</script>
<style scoped>
.library-h {
  max-height: 70vh;
  /* Adjust the height as needed for scrolling */
  overflow-y: auto;
  /* Enable vertical scrolling */
}


.card {
  border-radius: 17px;
  background-color: #ffffff;
}
.icon-class-bg {
  width: 30px;
  height: 30px;
  margin-left: 5px;
}
</style>