<template>
  <div class="form-group p-2">
    <!-- Fill Color Picker -->
    <div class="row pt-3">
      <div class="col">
        <label class="form-label">Fill Color</label>
        <color-picker v-model:pure-color="fillColor" :inline="true" />
      </div>
    </div>
    <!-- Stroke Color Picker -->
    <div class="row pt-3">
      <div class="col">
        <label class="form-label">Stroke Color</label>
        <color-picker v-model:pure-color="strokeColor" :inline="true" />
      </div>
    </div>
    <!-- Stroke Width -->
    <div class="row pt-3">
      <div class="col">
        <label class="form-label">Stroke Width</label>
        <input type="range" class="form-range" v-model="strokeWidth" min="0" max="20">
        <input type="number" v-model="strokeWidth" class="form-control mt-1">
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';
import { ColorPicker } from 'vue3-colorpicker';

defineOptions({
  name: 'Shape'
});

const props = defineProps({
  element: {
    required: true
  }
});

const emit = defineEmits(['propertyChanged']);

const rgbStringToHex = (rgbString) => {
  const match = rgbString.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);
  if (!match) return rgbString;
  const [r, g, b] = match.slice(1).map(Number);
  const toHex = (value) => {
    const hex = value.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

const fillColor = computed({
  get() {
    const fill = props.element.attr('fill') || '#000000';
    return rgbStringToHex(fill);
  },
  set(value) {
    const oldValue = props.element.attr('fill');
    props.element.attr('fill', value);
    emit('propertyChanged', {
      element: props.element,
      property: 'fill',
      before: oldValue,
      after: value
    });
  }
});

const strokeColor = computed({
  get() {
    const stroke = props.element.attr('stroke') || '#000000';
    return rgbStringToHex(stroke);
  },
  set(value) {
    const oldValue = props.element.attr('stroke');
    props.element.attr('stroke', value);
    emit('propertyChanged', {
      element: props.element,
      property: 'stroke',
      before: oldValue,
      after: value
    });
  }
});


const strokeWidth = computed({
  get() {
    return parseInt(props.element.attr('stroke-width')) || 0;
  },
  set(value) {
    const oldValue = props.element.attr('stroke-width');
    props.element.attr('stroke-width', value);
    emit('propertyChanged', {
      element: props.element,
      property: 'stroke-width',
      before: oldValue,
      after: value
    });
  }
});
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}

.form-label {
  font-weight: bold;
}

.color-picker {
  width: 100%;
}

input[type="range"] {
  width: 100%;
}
</style>
