<template>
  <div class="container-fluid bgcustom pt-3 pb-5 pe-4 ps-4">
    <div class="card shadow rounded-5 br-radius hborder pe-0 ps-1 py-3">
      <!--begin:breadcrumb-->
      <div class="row ps-4 pt-1">
        <div class="col">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Users View</li>
            </ol>
          </nav>
        </div>
      </div>
      <!--end:breadcrumb-->
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-8">
          <div class="row pb-2">
            <div class="col-12 col-sm-6 brand-text">
              <div>USERS</div> 
            </div>
            <!-- <div class="col-12 col-sm-6">
              <div class="input-group">                
                <i class="fa-solid fa-magnifying-glass input-group-text search-icon"></i>                
                <input class="form-control me-2 input-user space" type="search" placeholder="Search Users" aria-label="Search">
              </div>
            </div> -->
            <!-- <div class="col-12 col-sm-2">
              <select class="form-select space" aria-label="Default select example">
                <option selected>All</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div> -->
            <div class="col-12 d-none d-sm-block col-sm-6 button-center text-end">
              <button class="btn bg-button-h space p-1 w-50" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Invite User
              </button>
            </div>            
            <div class="col-12 d-sm-none text-center col-sm-6 button-center">
              <button class="btn bg-button-h space p-1 w-50" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Invite User
              </button>
            </div>            
          </div>
          <div class="alert alert-danger br-radius p-1 text-center" v-if="hasError">{{message}}</div>
          <div v-if="userLoading" class="row">
            <div class="col text-center">
              <loadersoda />
            </div>
          </div>
          <div v-else class="row">
            <div class="col">
              <div  class="card bg-card ">
                <div class="card-body p-1">
                  <form action="">
                    <div class="row py-2" v-for="(row,index) in rows" :key="index">
                      <div class="col">
                        <div class="bg-white mx-2 p-2 br_radius">
                          <div class="row">
                            <div class="col-3 col-md-3 col-lg-3 ps-3">
                              <img src="/assets/images/emad.png" class="logo-circle img-fluid width_img_user" alt=""> 
                            </div>                                  
                            <div class="col-9 col-md-5 col-lg-4 brand-text text-md-center text-lg-center p-0">
                              <span>{{row.first_name}} {{ row.last_name }}</span><br>
                              <span class="font-12">{{ row.email }}</span>
                            </div>
                            <div class="col-8 col-md-5 col-lg-3 brand-text text-center">
                              <span>{{row.access_role}}</span>
                            </div>
                            <div class="col-2 col-md-3 col-lg-2 text-center button-center font_Size_32">
                              <div class="dropdown d-flex" v-if="user_Role == 'owner' || user_Role == 'superadmin' ">
                                <span class="color_Line mx-2">|</span>
                                <div class="dropdown-toggle broder_h d-flex color_Line pt-2" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="fa-solid fa-ellipsis"></i>
                                </div>
                                <ul class="dropdown-menu card-border mouse" aria-labelledby="dropdownMenuButton1">
                                  <li v-if="user_Role =='owner'" class="dropdown-item" @click.prevent="update_User_Data('owner', row.id)" >Change Role To Owner</li>
                                  <li class="dropdown-item" @click.prevent="update_User_Data('superadmin', row.id)" >Change Role To Super Admin</li>
                                  <li class="dropdown-item" @click.prevent="update_User_Data('admin', row.id)" >Change Role To Admin</li>
                                  <li class="dropdown-item" @click.prevent="update_User_Data('user', row.id)" >Change Role To User</li>
                                  <li v-if="user_Role =='owner'" class="dropdown-item" @click.prevent="delete_User(row.id,index)" >Remove User</li>
                                </ul>
                              </div>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>                    
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--begin:modal-->
      <div class="row">
        <div class="col">
          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Add user</h5>
                  <button type="button" class="btn-close" id="modal_User_Close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="row pt-2">
                    <div class="col">
                      <input type="text" class="form-control" v-model="firstName" id="first"   placeholder="First Name" >
                    </div>
                  </div>
                  <div class="row pt-2">
                    <div class="col">
                      <input type="text" class="form-control" v-model="lastName" id="last"   placeholder="Last Name" >
                    </div>
                  </div>
                  <div class="row pt-2">
                    <div class="col">
                      <input type="email" class="form-control" v-model="email" id="filedEmail"  placeholder="Email" >
                    </div>
                  </div>
                  <div class="row pt-2">
                    <div class="col">                      
                      <select class="form-select" aria-placeholder="Select the Role" v-model="role" aria-label="Default select example">
                        <option value="user" selected>User</option>                      
                        <option value="owner">Owner</option>                      
                        <option value="superadmin">Super Admin</option>                      
                        <option value="admin">Admin</option>                      
                      </select>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                  <loader v-if="isloading" /> 
                  <button v-else type="button" class="btn bg-button" @click.prevent="add_User" >Send Invtation</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end modal-->
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'users',
  data(){
    return {
      userLoading: false,
      hasError: false,             
      message:"",
      firstName: "",
      lastName: "",
      email: "",
      role: "",
      isloading: false,
      rows: [],
      user_Role: "",
    }
  },
  computed: {
    ...mapGetters(['BASE_URL']),
  }, 
  methods: {
    getData_User_Auth(){
      this.userLoading = true;
      this.$http.get("/api/auth/user/").then(res => {
        this.userLoading = false;
        this.user_Role = res.data.data.access_role
        localStorage.setItem('access_role',this.user_Role);
      });
    },
    getData_User() {
      this.userLoading = true;
      this.$http.get("/api/subscriptions/users").then(res => {
        // console.log(res);
        this.userLoading = false;
        this.rows = res.data.data;
      }).catch(err => {
        // console.log(err);
        if(err.response.data.error){
          this.message = err.response.data.error;
        }
        if(err.response.data.errors){
          let key = Object.keys(err.response.data.errors)[0];
          this.message = err.response.data.errors[key][0];
        }
        this.hasError = true;
        this.userLoading = false;
      });
    },

    add_User(){
      this.isloading = true;
      this.$http.post("/api/subscriptions/users/add/",{
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        access_role: this.role,
      }).then(res => {
        this.isloading =false;
        document.getElementById('modal_User_Close').click();
        this.getData_User();
      }).catch(err => {
        // console.log(err);
        if(err.response.data.error){
          this.message = err.response.data.error;
        }
        if(err.response.data.errors){
          let key = Object.keys(err.response.data.errors)[0];
          this.message = err.response.data.errors[key][0];
        }
        this.hasError = true;
        this.isloading =false;
      });
    },

    update_User_Data(change,user_id) {
      this.$http.put(`/api/subscriptions/users/${user_id}/update/`,{
        access_role:change,
      }).then(res => {
        window.location.href ="/users";
      }).catch(err => {
        if(err.response.data.error){
          this.message = err.response.data.error;
        }
        if(err.response.data.errors){
          let key = Object.keys(err.response.data.errors)[0];
          this.message = err.response.data.errors[key][0];
        }
        this.hasError = true;
      });
    },
    delete_User(index,id_User){
      swal({
        title: "Are you sure?",
        text: "You will not be able to recover this User!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, delete it!",
        closeOnConfirm: false
      },
      () => this.remove_User(index)
      );
    },
    remove_User(id_User,index){
      this.$http.delete(`/api/subscriptions/users/${id_User}/delete/`).then(res => {
        let buttonCancel = document.getElementsByClassName('cancel');
        for(let i =0; i<buttonCancel.length; i++){
          buttonCancel[i].click();
        }
        this.rows.splice(index, 1);
        window.location.href="/users";
      }).catch(err => {
        let canceled = document.getElementsByClassName('cancel');
        for(let i =0; i<canceled.length; i++){
          canceled[i].click();
        }
        if(err.response.data.error){
          this.message = err.response.data.error;
        }
        if(err.response.data.errors){
          let key = Object.keys(err.response.data.errors)[0];
          this.message = err.response.data.errors[key][0];
        }
        this.hasError = true;
      });
    },
  },
  created() {
    this.getData_User();
    this.getData_User_Auth();
  }
}
</script>