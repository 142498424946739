<template>
  <!-- Header Section -->
  <div class="container-fluid bgcustom pt-3 pb-5 mt-2">
    <div class="card shadow rounded-5 hborder mx-auto checkout-container" style="max-width: 1200px;">
      <!-- Logo Section -->
      <div class="text-center">
        <img src="/assets/images/logo-soda.svg" class="logo-width mt-3" alt="Company Logo" />
      </div>
      <div class="flex-grow-1 text-center mt-5">
        <h3 class="fw-bold mb-3 p-2 text-danger">Access All Features – Upgrade to Keep Generating Stunning Content</h3>
      </div>
      <!-- Error Display -->
      <div v-if="errors.length" class="alert alert-danger mx-4 text-center p-1 br-radius">
        <span v-for="(error, index) in errors" :key="index" class="text-color">{{ error }}</span>
      </div>
      <!-- Checkout Content -->
      <div class="row p-2">
        <!-- Left Column: Plan Details -->
        <div class="col-md-6 col-sm-12 mb-4 d-flex flex-column justify-content-center">
          <div class="plan-details">

            <h3 class="plan-title">Subscription To: {{ planDetails?.name || 'Loading...' }}</h3>

            <p class="plan-price mb-2">
              <span class="currency-symbol">$</span>{{ formattedPrice || '0' }}
              <span class="billing-cycle">/ {{ billingCycleLabel }}</span>
            </p>
            <p class="pay-now-info">You will be charged <strong>$ {{ formattedPrice }}</strong> immediately for the
              selected billing cycle.</p>
            <!-- Updated Features List -->
            <ul class="list-unstyled plan-features lh-lg">
              <li v-for="(feature, index) in this.planDetails?.features" :key="index" class="feature-item">
                <i class="fa-solid fa-check iconcolor"></i> {{ feature.name }}
              </li>
            </ul>
          </div>
        </div>
        <!-- Right Column: Payment Form -->
        <div class="col-md-6 col-sm-12">
          <!-- Show loading indicator while processing -->
          <div v-if="isLoading" class="text-center">
            <loader />
          </div>
          <div class="payment-form-container">
            <h4 class="mb-4 text-center">Enter Your Payment Details</h4>
            <PaymentForm :thenPromising="handleSubmit" />
          </div>
        </div>
        <div class="text-center mt-5">
          <img src="/assets/images/stripe.png" class="logo-widths mt-3" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentForm from "@/views/Billing/PaymentForm.vue";
import { fetchPlans, upgradeSubscription } from "@/apis";
import { mapGetters } from "vuex";
import globalErrorHandler from "@/globalErrorHandler";

export default {
  name: "Checkout",
  data() {
    return {
      planDetails: null,
      errors: [],
      planId: this.$route.params.id,
      isLoading: false,
    };
  },
  components: {
    PaymentForm,
  },
  computed: {
    ...mapGetters(['GET_BASE_SUBSCRIPTION']),
    formattedPrice() {
      if (!this.planDetails) return '';
      const selectedPricing = this.planDetails.pricing.find(p => p.id === parseInt(this.planId, 10));
      return selectedPricing ? `${(selectedPricing.amount / 100).toFixed(2)}` : '';
    },
    billingCycleLabel() {
      const selectedPricing = this.planDetails?.pricing.find(p => p.id === parseInt(this.planId, 10));
      return selectedPricing?.interval === 'year' ? 'year' : 'month';
    },
    planFeatures() {
      if (!this.planDetails) return [];
      const selectedPricing = this.planDetails.pricing.find(p => p.id === parseInt(this.planId, 10));
      return selectedPricing ? Object.entries(selectedPricing.features).map(([key, value]) => {
        return `${key.replace('_', ' ')}: ${value === true ? 'Yes' : value === false ? 'No' : value}`;
      }) : [];
    },
  },
  methods: {
    async fetchPlanDetails() {
      try {
        const response = await fetchPlans();
        const plans = response.results.slice(1);

        const allPricings = plans.flatMap(plan => plan.pricing);
        const selectedPricing = allPricings.find(price => price.id === parseInt(this.planId, 10));

        if (selectedPricing) {
          this.planDetails = plans.find(plan => plan.pricing.includes(selectedPricing));
        } else {
          console.warn(`Pricing ID ${this.planId} not found in any plans.`);
          this.planDetails = null;
        }

      } catch (error) {
        console.log('Error fetching plan details:', error);
        this.errors = globalErrorHandler(error);
      }
    },

    async handleSubmit() {
      this.isLoading = true; // Activate loading state
      this.message = ""; // Clear any previous messages
      try {
        // Check if subscription ID is available
        const subscriptionId = this.GET_BASE_SUBSCRIPTION?.id;
        if (!subscriptionId) {
          throw new Error('No subscription ID available for resubscription.');
        }

        // Call upgradeSubscription with the subscription ID and pricing
        await upgradeSubscription(subscriptionId, { pricing: this.planId });

        // Display success message and reset loading state
        this.message = "Your subscription has been successfully reactivated!";
        this.isLoading = false;

        // Redirect to home after a delay
        setTimeout(() => {
          this.$router.push({ path: '/billing' }).then(() => {
            window.location.reload();
          });
        }, 1000);
      } catch (error) {
        // Handle and display error using globalErrorHandler
        this.isLoading = false; // Reset loading state on error
        const errors = globalErrorHandler(error); // Get formatted errors from the handler
        this.message = errors.length > 0 ? errors.join(" ") : "An unexpected error occurred.";
        console.error("Error during resubscription:", errors);
      }
    }

  },
  created() {
    this.fetchPlanDetails();
  },
};
</script>

<style scoped>
.card {
  border-radius: 17px;
}

.checkout-container {
  max-width: 1200px;
  margin-top: 20px;
  padding: 1rem;
}

.bgcustom {
  background-color: #f9f9f9;
}

.logo-width {
  width: 150px;
}

.logo-widths {
  width: 150px;
}

.plan-details {
  background-color: #f7f7f7;
  padding: 2rem;
  border-radius: 8px;
}

.plan-title {
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.plan-price {
  font-size: 2rem;
  font-weight: 700;
  color: #ED3341;
}

.pay-now-info {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
}

.currency-symbol {
  font-size: 1.5rem;
}

.billing-cycle {
  font-size: 1rem;
  color: #666;
}

.plan-features {
  list-style: none;
  padding: 0;
  margin-top: 1rem;
  font-size: 1rem;
  color: #333;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  color: #333;
}

.iconcolor {
  color: #F21A61;
  margin-right: 8px;
}

.payment-form-container {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.alert-danger {
  border-radius: 8px;
  font-size: 0.9rem;
}

/* Tablet Responsiveness */
@media (max-width: 992px) {
  .checkout-container {
    max-width: 100%;
    padding: 1rem;
  }

  .plan-details {
    padding: 1.5rem;
  }

  .plan-price {
    font-size: 1.75rem;
  }

  .plan-title {
    font-size: 1.5rem;
  }

  .feature-item {
    font-size: 0.95rem;
  }

  .pay-now-info {
    font-size: 0.9rem;
  }

  .payment-form-container {
    padding: 1.5rem;
  }
}

/* Mobile Responsiveness */
@media (max-width: 767px) {
  .checkout-container {
    max-width: 100%;
    padding: 0;
  }

  .text-center {
    font-size: 16px;
    font-weight: bold;
  }

  .logo-widths {
    width: 150px;
    margin-top: -41px !important;
  }

  .row.p-5 {
    padding: 1rem;
  }

  .plan-details,
  .payment-form-container {
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .plan-title {
    font-size: 1.3rem;
    text-align: center;
  }

  .plan-price {
    font-size: 1.5rem;
    text-align: center;
  }

  .billing-cycle {
    font-size: 0.9rem;
  }

  .pay-now-info {
    text-align: center;
  }

  .feature-item {
    font-size: 0.9rem;
  }

  .logo-width {
    width: 120px;
  }

  .text-danger {
    font-size: 1.2rem;
  }
}

.text-danger {
  color: #ED3341;
}
</style>
