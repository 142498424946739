import {createRouter, createWebHistory} from 'vue-router'
import store from '@/store';
import HomeView from '../views/HomeView.vue';
import UserLogin from '@/views/User/UserLogin.vue';
import UserRgister from '@/views/User/UserRgister.vue';
import BrandView from '@/views/Brands/BrandView.vue';
import CreateBrand from '@/views/Brands/CreateBrand.vue';
import UpdateBrand from '@/views/Brands/UpdateBrand.vue';
import CreateDesign from '@/views/Posts/CreateDesign.vue';
import UpdateDesign from '@/views/Posts/UpdateDesign.vue';
import Users from '@/views/User/Users.vue';
import BillingPage from '@/views/User/BillingPage.vue';
import LibraryPage from '@/views/User/LibraryPage.vue';
import EditorPage from '@/views/Posts/EditorPage.vue';
import PlanPage from '@/views/User/PlanPage.vue';
import EmailVerify from '@/views/User/EmailVerify.vue';
import UserProfile from '@/views/User/UserProfile.vue';
import ForgetPassword from '@/views/User/ForgetPassword.vue';
import BrandAnalysis from '@/views/Brands/BrandAnalysis.vue';
import FreeTrialExpired from '@/views/User/FreeTrialExpired.vue';
import NotFound from '@/components/NotFound.vue';
import InvitationـVerify from '@/views/User/InvitationـVerify.vue';
import Unpaid_Credit from '@/views/User/Unpaid_Credit.vue';
import Checkout from "@/views/Billing/Checkout.vue";
import NewBilling from "@/views/Billing/NewBilling.vue";
import SubscriptionListView from "@/views/Billing/New/SubscriptionDetails.vue";
import SVGEditor from "@/views/Editor/SVGEditor.vue";

const routes = [
  {
    path: '/svgeditor',
    name: 'SVGEditor',
    component: SVGEditor,
    props: (route) => ({svg_path: route.query.svg_path}),
  },
  {
    path: '/billing',
    name: 'billing',
    component: NewBilling,
  },
  {
    path: '/subscriptions/:type',
    name: 'SubscriptionList',
    component: SubscriptionListView,
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/login',
    name: 'login',
    component: UserLogin,
  },
  {
    path: '/register',
    name: 'register',
    component: UserRgister,
  },
  {
    path: '/brandview',
    name: 'brandview',
    component: BrandView,
  },
  {
    path: '/cbrand',
    name: 'cbrand',
    component: CreateBrand,
  },
  {
    path: '/brandanalysis/:id',
    name: 'brandanalysis',
    component: BrandAnalysis,
    props: true,
  },
  {
    path: '/updatebrand/:id',
    name: 'updatebrand',
    component: UpdateBrand,
  },
  {
    path: '/cdesign/:key',
    name: 'cdesign',
    component: CreateDesign,
  },
  {
    path: '/updesign/:id',
    name: 'updesign',
    component: UpdateDesign,
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
  },
  {
    path: '/oldbilling',
    name: 'oldbilling',
    component: BillingPage,
  },
  {
    path: '/library',
    name: 'library',
    component: LibraryPage,
  },
  {
    path: '/editor',
    name: 'editor',
    component: EditorPage,
  },
  {
    path: '/plan',
    name: 'plan',
    component: PlanPage,
  },
  {
    path: '/emailverify/:email',
    name: 'EmailVerify',
    component: EmailVerify,
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: UserProfile,
  },
  {
    path: '/forgetpassword',
    name: 'ForgetPassword',
    component: ForgetPassword,
  },
  {
    path: '/free-trial-expired',
    name: 'free-trial-expired',
    component: FreeTrialExpired,
  },
  {
    path: '/:pathMatch(.*)*', // Matches any route that is not found
    name: 'NotFound',
    component: NotFound,
  },
  {
    path: '/invitation/verify',
    name: 'invitation',
    component: InvitationـVerify,
  },
  {
    path: '/unpaid',
    name: 'Unpaid_Credit',
    component: Unpaid_Credit,
  },
  {
    path: '/checkout/:id',
    name: 'Checkout',
    component: Checkout
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Existing code
  store.commit('SET_ROUTE', to);

  // Start the global loading indicator
  store.commit('SET_GLOBAL_LOADING', true);

  const token = localStorage.getItem('token');

  if (token) {
    // Always update baseSubscription on route change
    await store.dispatch('updateBaseSubscription');
    const baseSubscription = store.getters.GET_BASE_SUBSCRIPTION;
    // Redirect based on updated subscription status
    if (
      baseSubscription?.status === 'expired' &&
      baseSubscription.plan === 'free' &&
      to.name !== 'free-trial-expired' &&
      !to.path.startsWith('/checkout') // Allow access to checkout path
    ) {
      return next({name: 'free-trial-expired'});
    }

    if (
      baseSubscription?.status === 'expired' &&
      baseSubscription.plan === 'paid' &&
      to.name !== 'Unpaid_Credit'
    ) {
      return next({name: 'Unpaid_Credit'});
    }


    // Prevent logged-in users from accessing the login page
    if (to.path === '/login') {
      return next('/');
    }
  } else {
    // If not authenticated, redirect to login if the route requires authentication
    if (to.meta.requiresAuth) {
      return next('/login');
    }
  }

  next();
});


router.afterEach(() => {
  // Stop the global loading indicator
  // Delay stopping the loader to give the next page time to render
  setTimeout(() => {
    store.commit('SET_GLOBAL_LOADING', false);
  }, 800); // Adjust the delay as needed (e.g., 500ms)
});

export default router;