<template>
    <div>
        <img src="/assets/images/new-logo.gif" style="width:70%" alt="">
        <!-- <img src="/assets/images/min-loader.gif" class="w-50" alt=""> -->
    </div>
</template>
<script>
export default {
    name: 'minloader',
}
</script>