<template>
  <div class="container-fluid bgcustom pt-3 pb-5 pe-4 ps-4">
    <div class="card shadow rounded-5 hborder pe-0 ps-1 pt-0 pb-0">
      <div class="card-body pt-0">
        <div class="row justify-content-center pt-2">
          <div class="col col-sm-6 text-center">
            <div v-if="hasError" class="alert alert-danger p-1 text-center br-radius mx-4"> {{ message }} </div>
          </div>
        </div>
        <!--begin:breadcrumb-->
        <div class="row pt-2 pb-3">
          <div class="col ps-3">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item">
                  <router-link to="/brandview"><img src="/assets/icons/back-arrow.svg" alt="icon"
                      class="icon-class-bg" /></router-link>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <h4 class="text-center mb-3 text-uppercase font-bold">Create Brand</h4>
        <!--end:breadcrumb-->
        <!-- Loader -->
        <div v-if="loading" class="row">
          <div class="col text-center">
            <loadersoda />
          </div>
        </div>
        <!-- Form content -->
        <div v-else class="row justify-content-center">
          <div class="col-12 col-sm-6">
            <form @submit.prevent="createBrandFN()">
              <!-- Accordion: Brand Info (Mandatory Fields) -->
              <div class="accordion formB" id="accordionExample">
                <div class="accordion-item bg-gray">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button formB" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <i class="fa fa-tag font-color me-3"></i> Brand Main Info<span class="text-danger">*</span>
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse hide" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <!-- Brand Name (Mandatory) -->
                      <div class="row pt-2">
                        <div class="col">
                          <div class="row">
                            <div class="col">
                              <label for="">Brand Name <span class="text-danger">*</span></label>
                              <input type="text" v-model="name" class="form-control" required>
                            </div>
                          </div>
                          <!-- Categories (Mandatory) -->
                          <div class="row pt-2">
                            <div class="col pt-2">
                              <label for="">Categories <span class="text-danger">*</span></label>
                              <select v-model="categories" @change="handleCategoryChange" class="form-select" required>
                                <option v-for="(row, index) in rows" :key="index" :value="row.id">{{
                                  row.title }}</option>
                                <option value="others">Other</option>
                              </select>
                            </div>
                          </div>
                          <!-- Subcategories (Conditional) -->
                          <div v-if="categories !== 'others'" class="row pt-2">
                            <div class="col pt-2">
                              <label for="">Subcategories <span class="text-danger">*</span></label>
                              <!-- Multiselect dropdown -->
                              <multiselect v-model="subcategories" :options="items" placeholder="Select subcategories"
                                label="title" track-by="id" :multiple="true" :close-on-select="false"
                                :clear-on-select="false" :hide-selected="true" class="form-control" />
                            </div>
                          </div>
                          <!-- New Category (Only show when "Other" is selected) -->
                          <div v-if="categories === 'others'" class="row pt-2">
                            <div class="col pt-2">
                              <label for="">Other Categories <span class="text-danger">*</span></label>
                              <input type="text" v-model="otherCategories" class="form-control" required
                                placeholder="Please specify">
                            </div>
                          </div>
                          <div class="row pt-2">
                            <div class="col pt-2">
                              <label for="">Brand Logo <span class="text-danger">*</span></label>
                              <div style="position: relative;">
                                <div class="form-img pt-2"><i class="fa-solid fa-cloud-arrow-up font-26 pe-2"></i> Drag
                                  a
                                  image here or click to choose a file.</div>
                                <DropZone class="border-D" @addedFile="onFileAdd" message="Upload a file"
                                  :maxFiles="Number(3)" :maxFileSize="10000000" url="http://localhost:5000/item"
                                  :uploadOnDrop="false" :multipleUpload="true" :parallelUpload="3" />
                              </div>
                            </div>
                          </div>
                          <div class="row pt-5">
                            <div class="col">
                              <label for="" class="pe-4">Brand Primary Color:</label>
                              <color-picker v-model:pureColor="primary_color" />
                            </div>
                            <div class="col">
                              <label for="" class="pe-4">Brand Secondary Color:</label>
                              <color-picker v-model:pureColor="secondary_color" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end:brandInfo-->
              <!--begin:Contact Information-->
              <div class="accordion formB pt-2" id="accordionBrandIdentity">
                <div class="accordion-item bg-gray">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button bg-gray" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                      <i class="fa fa-phone font-color me-3"></i> Contacts Information
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingOne"
                    data-bs-parent="#accordionBrandIdentity">
                    <div class="accordion-body">
                      <div class="row pt-2">
                        <div class="col">
                          <label for="">Phone Number</label>
                          <input type="text" v-model="phone" class="form-control">
                        </div>
                        <div class="col">
                          <label for="">Other Phone</label>
                          <input type="text" v-model="otherphone" class="form-control">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for="">
                            Whatsapp Phone Number</label>
                          <input type="text" v-model="whatsApp" class="form-control">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for="">Brand Email</label>
                          <input type="email" v-model="email" class="form-control">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for="">Brand Website</label>
                          <input type="text" v-model="website" class="form-control">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for="">Brand Address</label>
                          <input type="text" v-model="address" class="form-control">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end:Contact Information-->
              <!--begin:Brand Marketing Details-->
              <div class="accordion formB pt-2" id="accordionBrandMarketing">
                <div class="accordion-item bg-gray">
                  <h2 class="accordion-header" id="headingBrandMarketing">
                    <button class="accordion-button bg-gray" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseBrandMarketing" aria-expanded="true"
                      aria-controls="collapseBrandMarketing">
                      <i class="fa fa-lightbulb font-color me-3"></i> Brand Marketing Details
                    </button>
                  </h2>
                  <div id="collapseBrandMarketing" class="accordion-collapse collapse"
                    aria-labelledby="headingBrandMarketing" data-bs-parent="#accordionBrandMarketing">
                    <div class="accordion-body">
                      <div class="row pt-2">
                        <div class="col">
                          <label for="">Brand Slogan</label>
                          <input type="text" v-model="slogan" class="form-control">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for="">Opening Date</label>
                          <input type="date" v-model="opening_date" class="form-control">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for="">Unique Selling Point</label>
                          <input type="text" v-model="unique_selling_point" class="form-control">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end:Brand Marketing Details-->
              <!--begin:mediaLinks-->
              <div class="accordion formB pt-2" id="accordionMediaLinks">
                <div class="accordion-item bg-gray">
                  <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button formB" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <i class="fa fa-globe font-color me-3"></i> Social Media Links
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                    data-bs-parent="#accordionMediaLinks">
                    <div class="accordion-body">
                      <div class="row pt-2">
                        <div class="col">
                          <label for=""><i class="fa-brands fa-facebook font-color"></i> Facebook</label>
                          <input type="text" v-model="facebook" placeholder="LINK" class="form-control font-14">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for=""><i class="fa-brands fa-twitter font-color"></i> X</label>
                          <input type="text" v-model="twitter" placeholder="LINK" class="form-control font-14">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for=""><i class="fa-brands fa-instagram font-color"></i> Instagram</label>
                          <input type="text" v-model="instagram" placeholder="USER" class="form-control font-14">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for=""><i class="fa-brands fa-snapchat font-color"></i> Snapchat</label>
                          <input type="text" v-model="snapchat" placeholder="USER" class="form-control font-14">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for=""><i class="fa-brands fa-tiktok font-color"></i> TikTok</label>
                          <input type="text" v-model="tiktok" placeholder="USER" class="form-control font-14">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for=""><i class="fa-brands fa-linkedin font-color"></i> LinkedIn</label>
                          <input type="text" v-model="linkedin" placeholder="LINK" class="form-control font-14">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for=""><i class="fa-brands fa-behance font-color"></i> Behance</label>
                          <input type="text" v-model="behance" placeholder="LINK" class="form-control font-14">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for=""><i class="fa-brands fa-youtube font-color"></i> Youtube</label>
                          <input type="text" placeholder="LINK" v-model="youtube" class="form-control font-14">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for=""><i class="fa-brands fa-pinterest font-color"></i> Pinterest</label>
                          <input type="text" v-model="pinterest" placeholder="LINK" class="form-control font-14">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end:mediaLinks-->
              <!--begin:Target_Audience-->
              <div class="accordion formB pt-2" id="accordionTarget">
                <div class="accordion-item bg-gray">
                  <h2 class="accordion-header" id="headingAudience">
                    <button class="accordion-button bg-gray" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseTarget_Audience" aria-expanded="true" aria-controls="collapseTwo">
                      <i class="fa fa-bullseye font-color me-3"></i> Target Audience
                    </button>
                  </h2>
                  <div id="collapseTarget_Audience" class="accordion-collapse collapse hide"
                    aria-labelledby="headingAudience" data-bs-parent="#accordionTarget">
                    <div class="accordion-body">

                      <!-- Target Location -->
                      <div class="row pt-0 pb-3">
                        <div class="col">
                          <label for="interests">Target Location</label>
                          <multiselect v-model="selectedCountry" :options="countries" :searchable="true"
                            :close-on-select="true" :clear-on-select="false" placeholder="Search for a country"
                            :show-labels="false" track-by="name" label="name">
                          </multiselect>
                        </div>
                      </div>
                      <!-- Age -->
                      <div class="row">
                        <div class="col">
                          <label for="ageFrom">Age (From)</label>
                          <select v-model="ageFrom" class="form-select">
                            <option v-for="n in 48" :key="n" :value="n + 17">{{ n + 17 }}</option>
                          </select>
                        </div>
                        <div class="col">
                          <label for="ageTo">Age (To)</label>
                          <select v-model="ageTo" class="form-select">
                            <option v-for="n in 48" :key="n" :value="n + 17">{{ n + 17 }}</option>
                          </select>
                        </div>
                      </div>
                      <!-- Gender -->
                      <div class="row pt-3">
                        <div class="col">

                          <label class="me-3">Gender:</label>

                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="gender-all" v-model="gender" value="all" />
                            <label class="form-check-label" for="gender-all">All</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="gender-male" v-model="gender"
                              value="male" />
                            <label class="form-check-label" for="gender-male">Male</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="gender-female" v-model="gender"
                              value="female" />
                            <label class="form-check-label" for="gender-female">Female</label>
                          </div>
                        </div>
                      </div>
                      <!-- Interests -->
                      <div class="row pt-2">
                        <div class="col">
                          <label for="interests">Interests</label>
                          <vue3-tags-input :tags="interests" placeholder="Add Your Interests"
                            @on-tags-changed="handleChangeInterestTags" />
                        </div>
                      </div>
                      <!-- Behaviors -->
                      <div class="row pt-2">
                        <div class="col">
                          <label for="behaviors">Behaviors</label>
                          <vue3-tags-input :tags="behaviors" placeholder="Add Your Behaviors"
                            @on-tags-changed="handleChangeBehaviorTags" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end:Target_Audience-->
              <div class="row pt-2" v-if="createB">
                <div class="col text-center">
                  <loader />
                </div>
              </div>
              <div v-else class="row pt-3">
                <div class="col text-end pb-3">
                  <button type="submit" class="btn bg-button px-5">Create</button>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { DropZone } from 'dropzone-vue';
import Multiselect from 'vue-multiselect';
import rgbHex from 'rgb-hex';
import Vue3TagsInput from 'vue3-tags-input';

export default defineComponent({
  name: 'cbrand',
  components: {
    DropZone,
    Multiselect,
    Vue3TagsInput
  },
  data() {
    return {
      hasError: false,
      message: "",
      name: "",
      website: "",
      phone: "",
      otherphone: "",
      whatsApp: "",
      email: "",
      address: "",
      imagelogo: "",
      primary_color: "",
      secondary_color: "",
      categories: 0,
      subcategories: [],
      slogan: "",
      opening_date: "",
      unique_selling_point: "",
      facebook: "",
      twitter: "",
      instagram: "",
      snapchat: "",
      tiktok: "",
      linkedin: "",
      behance: "",
      youtube: "",
      pinterest: "",
      rows: [],
      items: [],
      otherCategories: "",
      loading: true,
      createB: false,
      ageFrom: 18,
      ageTo: 65,
      gender: 'all',
      interests: [],
      behaviors: [],
      selectedCountry: null,
      countries: [

        { name: 'Algeria' },
        { name: 'Bahrain' },
        { name: 'Comoros' },
        { name: 'Djibouti' },
        { name: 'Egypt' },
        { name: 'Iraq' },
        { name: 'Jordan' },
        { name: 'Kuwait' },
        { name: 'Lebanon' },
        { name: 'Libya' },
        { name: 'Mauritania' },
        { name: 'Morocco' },
        { name: 'Oman' },
        { name: 'Palestine' },
        { name: 'Qatar' },
        { name: 'Saudi Arabia' },
        { name: 'Somalia' },
        { name: 'Sudan' },
        { name: 'Syria' },
        { name: 'Tunisia' },
        { name: 'United Arab Emirates' },
        { name: 'Yemen' },
        { name: 'Afghanistan' },
        { name: 'Albania' },
        { name: 'Andorra' },
        { name: 'Angola' },
        { name: 'Antigua and Barbuda' },
        { name: 'Argentina' },
        { name: 'Armenia' },
        { name: 'Australia' },
        { name: 'Austria' },
        { name: 'Azerbaijan' },
        { name: 'Bahamas' },
        { name: 'Bangladesh' },
        { name: 'Barbados' },
        { name: 'Belarus' },
        { name: 'Belgium' },
        { name: 'Belize' },
        { name: 'Benin' },
        { name: 'Bhutan' },
        { name: 'Bolivia' },
        { name: 'Bosnia and Herzegovina' },
        { name: 'Botswana' },
        { name: 'Brazil' },
        { name: 'Brunei' },
        { name: 'Bulgaria' },
        { name: 'Burkina Faso' },
        { name: 'Burundi' },
        { name: 'Cabo Verde' },
        { name: 'Cambodia' },
        { name: 'Cameroon' },
        { name: 'Canada' },
        { name: 'Central African Republic' },
        { name: 'Chad' },
        { name: 'Chile' },
        { name: 'China' },
        { name: 'Colombia' },
        { name: 'Congo (Congo-Brazzaville)' },
        { name: 'Costa Rica' },
        { name: 'Croatia' },
        { name: 'Cuba' },
        { name: 'Cyprus' },
        { name: 'Czech Republic' },
        { name: 'Democratic Republic of the Congo' },
        { name: 'Denmark' },
        { name: 'Dominica' },
        { name: 'Dominican Republic' },
        { name: 'Ecuador' },
        { name: 'El Salvador' },
        { name: 'Equatorial Guinea' },
        { name: 'Eritrea' },
        { name: 'Estonia' },
        { name: 'Eswatini' },
        { name: 'Ethiopia' },
        { name: 'Fiji' },
        { name: 'Finland' },
        { name: 'France' },
        { name: 'Gabon' },
        { name: 'Gambia' },
        { name: 'Georgia' },
        { name: 'Germany' },
        { name: 'Ghana' },
        { name: 'Greece' },
        { name: 'Grenada' },
        { name: 'Guatemala' },
        { name: 'Guinea' },
        { name: 'Guinea-Bissau' },
        { name: 'Guyana' },
        { name: 'Haiti' },
        { name: 'Honduras' },
        { name: 'Hungary' },
        { name: 'Iceland' },
        { name: 'India' },
        { name: 'Indonesia' },
        { name: 'Iran' },
        { name: 'Ireland' },
        { name: 'Italy' },
        { name: 'Ivory Coast' },
        { name: 'Jamaica' },
        { name: 'Japan' },
        { name: 'Kazakhstan' },
        { name: 'Kenya' },
        { name: 'Kiribati' },
        { name: 'Kosovo' },
        { name: 'Kyrgyzstan' },
        { name: 'Laos' },
        { name: 'Latvia' },
        { name: 'Lesotho' },
        { name: 'Liberia' },
        { name: 'Liechtenstein' },
        { name: 'Lithuania' },
        { name: 'Luxembourg' },
        { name: 'Madagascar' },
        { name: 'Malawi' },
        { name: 'Malaysia' },
        { name: 'Maldives' },
        { name: 'Mali' },
        { name: 'Malta' },
        { name: 'Marshall Islands' },
        { name: 'Mexico' },
        { name: 'Micronesia' },
        { name: 'Moldova' },
        { name: 'Monaco' },
        { name: 'Mongolia' },
        { name: 'Montenegro' },
        { name: 'Mozambique' },
        { name: 'Myanmar (Burma)' },
        { name: 'Namibia' },
        { name: 'Nauru' },
        { name: 'Nepal' },
        { name: 'Netherlands' },
        { name: 'New Zealand' },
        { name: 'Nicaragua' },
        { name: 'Niger' },
        { name: 'Nigeria' },
        { name: 'North Korea' },
        { name: 'North Macedonia' },
        { name: 'Norway' },
        { name: 'Pakistan' },
        { name: 'Palau' },
        { name: 'Panama' },
        { name: 'Papua New Guinea' },
        { name: 'Paraguay' },
        { name: 'Peru' },
        { name: 'Philippines' },
        { name: 'Poland' },
        { name: 'Portugal' },
        { name: 'Romania' },
        { name: 'Russia' },
        { name: 'Rwanda' },
        { name: 'Saint Kitts and Nevis' },
        { name: 'Saint Lucia' },
        { name: 'Saint Vincent and the Grenadines' },
        { name: 'Samoa' },
        { name: 'San Marino' },
        { name: 'Sao Tome and Principe' },
        { name: 'Senegal' },
        { name: 'Serbia' },
        { name: 'Seychelles' },
        { name: 'Sierra Leone' },
        { name: 'Singapore' },
        { name: 'Slovakia' },
        { name: 'Slovenia' },
        { name: 'Solomon Islands' },
        { name: 'South Africa' },
        { name: 'South Korea' },
        { name: 'South Sudan' },
        { name: 'Spain' },
        { name: 'Sri Lanka' },
        { name: 'Suriname' },
        { name: 'Sweden' },
        { name: 'Switzerland' },
        { name: 'Tajikistan' },
        { name: 'Tanzania' },
        { name: 'Thailand' },
        { name: 'Timor-Leste' },
        { name: 'Togo' },
        { name: 'Tonga' },
        { name: 'Trinidad and Tobago' },
        { name: 'Tunisia' },
        { name: 'Turkey' },
        { name: 'Turkmenistan' },
        { name: 'Tuvalu' },
        { name: 'Uganda' },
        { name: 'Ukraine' },
        { name: 'United Kingdom' },
        { name: 'United States' },
        { name: 'Uruguay' },
        { name: 'Uzbekistan' },
        { name: 'Vanuatu' },
        { name: 'Vatican City' },
        { name: 'Venezuela' },
        { name: 'Vietnam' },
        { name: 'Zambia' },
        { name: 'Zimbabwe' }
      ]

    };
  },
  methods: {
    handleChangeInterestTags(newTags) {
      this.interests = newTags;
    },

    handleChangeBehaviorTags(newTags) {
      this.behaviors = newTags;
    },
    createBrandFN() {
      this.createB = true;
      const formData = new FormData();
      formData.append('name', this.name);
      formData.append('logo', this.imagelogo);
      // If 'others' is selected, send a placeholder ID (e.g., 999) and append the custom category as 'otherscat'
      if (this.categories === 'others') {
        formData.append('categories', "");
        formData.append('other_category', this.otherCategories); // Send custom category
      } else {
        formData.append('categories', this.categories); // Regular category
        const subcategoryIds = this.subcategories.map(subcategory => Number(subcategory.id));
        formData.append('subcategories', subcategoryIds); // Send as raw array
      }
      formData.append('slogan', this.slogan);
      formData.append('opening_date', this.opening_date);
      formData.append('website', this.website);
      formData.append('email', this.email);
      formData.append('phone', this.phone);
      formData.append('other_phone', this.otherphone);
      formData.append('address', this.address);
      formData.append('whatsapp', this.whatsApp);
      formData.append('unique_selling_point', this.unique_selling_point);
      if (this.primary_color) {
        formData.append('primary_color', '#' + rgbHex(this.primary_color));
      }
      if (this.secondary_color) {
        formData.append('secondary_color', '#' + rgbHex(this.secondary_color));
      }
      if (this.selectedCountry) {
        formData.append('country', this.selectedCountry.name);
      }
      formData.append('age_from', this.ageFrom.toString());
      formData.append('age_to', this.ageTo.toString());
      formData.append('gender', this.gender);
      formData.append('interests', JSON.stringify(this.interests));
      formData.append('behaviors', JSON.stringify(this.behaviors));

      //Social Media
      formData.append('facebook', this.facebook);
      formData.append('instagram', this.instagram);
      formData.append('tiktok', this.tiktok);
      formData.append('snapchat', this.snapchat);
      formData.append('youtube', this.youtube);
      formData.append('linkedin', this.linkedin);
      formData.append('x_link', this.twitter);
      formData.append('behance', this.behance);
      formData.append('pinterest', this.pinterest);


      // Make API call to create the brand
      this.hasError = false;
      this.$http.post("/api/subscriptions/brands/create/", formData)
        .then((res) => {
          this.createB = false;
          if (res.status === 200) {
            this.$router.push({ path: "/brandview" });
          }
        })
        .catch((err) => {
          // if (err.response && err.data && err.data.message) {
          //   this.message = err.data.message;
          // } else {
          //   this.message = "An unknown error occurred.";
          // }
          // this.hasError = true;
          if (err.data.error) {
          this.message = err.data.error;
          }
          if (err.data.errors) {
            const key = Object.keys(err.data.errors)[0];
            this.message = err.data.errors[key][0];
          }
          this.hasError = true;
          this.createB = false;
          window.scroll(0,0);
        });
    },
    Categories() {
      this.$http.get("/api/settings/categories/").then((res) => {
        this.loading = false;
        this.rows = res.data.categories;
      }).catch((err) => {
        if (err.data.error) {
          this.message = err.data.error;
        }
        if (err.data.errors) {
          const key = Object.keys(err.data.errors)[0];
          this.message = err.data.errors[key][0];
        }
        this.hasError = true;
      });
    },
    subCategories() {
      this.$http.get(`/api/settings/subcategories/${this.categories}/`).then((res) => {
        this.items = res.data.subcategories;
      }).catch((err) => {
        if (err.data.error) {
          this.message = err.data.error;
        }
        if (err.data.errors) {
          const key = Object.keys(err.data.errors)[0];
          this.message = err.data.errors[key][0];
        }
        this.hasError = true;
      });
    },
    handleCategoryChange() {
      if (this.categories !== 'others') {
        this.subCategories();
      }
    },
    onFileAdd(e) {
      this.imagelogo = e.file;
    },
  },
  created() {
    this.Categories();
    this.subCategories();
  },
});
</script>
<style scoped>
span.multiselect__tag {
  background: #f70081 !important;
}

.multiselect {
  width: 96%;
  max-height: 300px;
}

span.v3ti-tag {
  background: #f70081 !important;
}

.v3ti .v3ti-tag .v3ti-remove-tag {
  color: #000000 !important;
  transition: color .3s !important;
}

.v3ti .v3ti-tag .v3ti-remove-tag:hover {
  color: #ffffff !important;
}

.card {
  border-radius: 17px;
  background-color: #ffffff;
}

.icon-class-bg {
  width: 30px;
  height: 30px;
  margin-left: 5px;
}
</style>