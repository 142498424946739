<template>
  <div>
    <h4>Billing History</h4>
    <div class="table-scroll-container">
      <table class="table">
        <thead>
          <tr>
            <th>Invoice Date</th>
            <th>Description</th>
            <th>Subscription Period</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in history" :key="entry.id">
            <td>{{ formattedDate(entry?.created) }}</td>
            <td> Invoice For, {{ entry?.description || "-" }}</td>
            <td>{{ formattedDate(entry?.current_billing_cycle_start) }}</td>
            <td>{{ entry?.payment_intent?.amount / 100 }} $</td>
            <td>{{ entry?.status }}</td>
            <td>
              <a :href="entry?.url" download>
                <button class="btn btn-xs btn-primary">
                  <i class="fas fa-download"></i>
                </button>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    history: Array,
  },
  methods: {
    formattedDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }
  }
};
</script>

<style scoped>
.table {
  width: 100%;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.btn-primary {
  background-color: #f70081;
  border-color: #f70081;

}

.table-scroll-container {
  overflow-x: auto;
  max-width: 100%;
}

.table-scroll-container::-webkit-scrollbar {
  height: 8px;
}

.table-scroll-container::-webkit-scrollbar-thumb {
  background-color: #f21764;
  border-radius: 4px;
}

.table-scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table {
  width: 100%;
}

.btn-primary {
  background-color: #f70081;
  border-color: #f70081;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.page-button {
  margin: 0 0.25rem;
  padding: 0.5rem 1rem;
  border: none;
  background-color: #f70081;
  color: #fff;
  cursor: pointer;
}

.page-button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.page-button.active {
  background-color: #f31986;
  font-weight: bold;
}
</style>
