<template>
  <div class="d-flex align-items-center addon-card mb-3">
    <!-- Logo Column -->
    <div class="logo-column me-3">
      <img src="/assets/images/puzzle.svg" class="card-logo" alt="Company Logo" />
    </div>

    <!-- Content Column -->
    <div class="content-column flex-grow-1 text-start">
      <h6 class="font-w600 font-bold mb-2">{{ title }}</h6>
      <p class="mb-1">Total: {{ total }}</p>
      <button class="btn btn-primary" @click="$emit('add')">
        Add {{ title }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    title: String,
    used: Number,
    total: Number
  },
  computed: {
    ...mapGetters(['GET_BASE_SUBSCRIPTION']),
    progressPercentage() {
      return this.total ? (this.used / this.total) * 100 : 0;
    }
  }
};
</script>

<style scoped>
.addon-card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.logo-column {
  flex-shrink: 0;
}

.card-logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.content-column {
  flex-grow: 1;
}

.btn-primary {
  border-color: #f31986;
  color: #ffffff;
  background-color: #ED3341;
}
</style>
