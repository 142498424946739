<template>
  <div class="col-12 col-sm-8">
    <div class="row">
      <div class="col">
        <div class="billing-bg">
          <div class="text-color text-start pt-3">
            <div class="line-G" style="width:6%;"></div>
            Plans
          </div>
          <div class="row">
            <div class="col">
              <div class="payment-color font-20 ps-2">
                {{ GET_BASE_SUBSCRIPTION?.display_name }}
              </div>
              <p class="font-12 ps-3">From Your Subscription</p>
              <div class="row justify-content-center py-2">
                <div class="col-12 col-sm-3 col-md-4 d-flex pb-3 justify-content-center">
                  <div class="card" style="width: 14rem;">
                    <div class="card-body text-center">
                      <i class="fa-regular fa-user billing-color"></i>
                      <div class="card-title billing-color">USERS</div>
                      <loadersoda v-if="loading" class="text-center"/>
                      <div v-else> CURRENT {{ GET_BASE_SUBSCRIPTION?.pricing?.features?.users }}</div>
                    </div>
                    <button class="btn billing-button font-14" data-bs-toggle="modal"
                            data-bs-target="#seatsUpgradeModal">
                      ADD SEATS
                    </button>
                  </div>
                </div>

                <div class="col-12 col-sm-3 col-md-4 d-flex pb-3 justify-content-center">
                  <div class="card" style="width: 14rem;">
                    <div class="card-body text-center">
                      <i class="fa-regular fa-user billing-color"></i>
                      <div class="card-title billing-color">BRANDS</div>
                      <div>CURRENT {{ GET_BASE_SUBSCRIPTION?.pricing?.features?.brands }}</div>
                    </div>
                    <button class="btn billing-button font-14 space" data-bs-toggle="modal"
                            data-bs-target="#upgradeBrandsModal">
                      ADD BRANDS
                    </button>
                  </div>
                </div>

                <div class="col-12 col-sm-4 col-md-4 d-flex pb-3 justify-content-center">
                  <div class="card" style="width: 14rem;">
                    <div class="card-body text-center">
                      <i class="fa-regular fa-user billing-color"></i>
                      <!-- <img src="/assets/images/Vector-3.png" alt=""> -->
                      <div class="card-title billing-color">STORAGE</div>
                      <div>CURRENT {{ GET_BASE_SUBSCRIPTION?.pricing?.features?.storage }}</div>
                    </div>
                    <button class="btn billing-button font-14 space" data-bs-toggle="modal"
                            data-bs-target="#Upgrade_Storage_Modal">
                      ADD STORAGE
                    </button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="line-border"></div>
                <div class="col py-2 pe-5 text-end">
                  <button class="btn billing-button" data-bs-toggle="modal"
                          data-bs-target="#changeBillingModal">Upgrade
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--begin:modals-->
  <div class="modal fade" id="changeBillingModal" tabindex="-1" aria-labelledby="changeBillingModalLabel"
       style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header ps-0 pb-0">
          <h5 class="modal-title text-color font-25" id="changeBillingModalLabel">
            <div class="line-G" style="width: 38px;"></div>
            Upgrade From pro
          </h5>
          <div class="row">
            <div class="col d-flex ps-5">
              <div class="mx-2">Monthly</div>
              <div class="form-check form-switch"><input class="form-check-input" type="checkbox"
                                                         id="flexSwitchCheckDefault"><label
                class="form-check-label mx-1" for="flexSwitchCheckDefault">Yearly</label></div>
            </div>
          </div>
          <button type="button" class="btn-close billing-button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body bg-bubble">
          <div class="text-center padding-Right font-14 font-bold py-2">YOUR CURRENT PLAN : Agency Monthly</div>
          <div class="row">
            <div class="col">
              <div class="row"><!---->
                <div class="col-12 col-md-4 py-2">
                  <div class="card mouse br-radius design-card" id="plan_card_id0" style="width: 14rem;">
                    <div class="card-body"><h5 class="card-title font-bold">Pro plan</h5>
                      <div class="row">
                        <div class="col">
                          <div><h3>$27/Mo</h3></div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <ul class="padding_L">
                            <li>50 Credits / month</li>
                            <li>1 Brand</li>
                            <li>1 User Seat</li>
                            <li>AI graphic Design generator</li>
                            <li>AI content generator</li>
                            <li>2 GB Media Storage</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 py-2">
                  <div class="card mouse br-radius design-card" id="plan_card_id1" style="width: 14rem;">
                    <div class="card-body"><h5 class="card-title font-bold">Team plan</h5>
                      <div class="row">
                        <div class="col">
                          <div><h3>$69/Mo</h3></div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <ul class="padding_L">
                            <li>200 Credit / Month</li>
                            <li>Include 2 Brand , add up to 5</li>
                            <li>Include 2 Seats, add unlimited</li>
                            <li>AI graphic Design generator</li>
                            <li>AI content generator</li>
                            <li>4 GB Media Storage</li>
                            <li>API Access</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 py-2">
                  <div class="card mouse br-radius design-card disabled" id="plan_card_id2" style="width: 14rem;">
                    <div class="card-body"><h5 class="card-title font-bold">Agency plan</h5>
                      <div class="row">
                        <div class="col">
                          <div><h3>$169/Mo</h3></div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <ul class="padding_L">
                            <li>1000 Credit / Month</li>
                            <li>Include 5 Brands , add unlimited</li>
                            <li>Include 5 seats , add unlimited</li>
                            <li>AI graphic Design generator</li>
                            <li>AI content generator</li>
                            <li>16 GB Media Storage</li>
                            <li>API Access</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row py-3">
            <div class="col text-center">
              <button class="btn btn-generate w-50">Confirm and Pay</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--begin:modal-addSeatsUpgrade-->
  <div class="modal fade" id="seatsUpgradeModal" tabindex="-1" aria-labelledby="seatsUpgradeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header border-modal">
          <button type="button" class="btn-close billing-button" id="closeSeatsUpgrade" data-bs-dismiss="modal"
                  aria-label="Close"></button>
        </div>
        <div class="modal-body pt-0">
          <div class="row">
            <div class="col text-center">
              <div class="text-color font-20">Add Seats</div>
            </div>
          </div>
          <div class="row pt-2">
            <loadersoda v-if="loading"/>
            <div v-else class="col text-center">
              <div class="font-bold">The {{ GET_BASE_SUBSCRIPTION?.display_name }} Plan is limited to
                {{ GET_BASE_SUBSCRIPTION?.pricing?.features?.users }} users
              </div>
              <div>upgrade to the team plan or higher to add more users</div>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col text-center">
              <button type="button" @click="openSeats()" data-bs-toggle="modal" data-bs-target="#addSeatsModal"
                      :class="['btn', 'billing-button', 'w-75', 'm-auto', { 'disabled': !can_attach_user }]">
                Upgrade
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end:modal-addSeatsUpgrade-->

  <!--begin:modal-AddSeats-->
  <div class="modal fade" id="addSeatsModal" tabindex="-1" aria-labelledby="addSeatsModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header ps-0">
          <h5 class="modal-title text-color" id="addSeatsModalLabel">
            <div class="line-G" style="width: 38px;"></div>
            Add Seats
          </h5>
          <button type="button" class="btn-close billing-button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger text-center p-1 br-radius" v-if="UpgradeError">{{ message }}</div>
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col text-center">
                  <button class="billing-button px-2 mx-2 border-hidden" @click="decrease_Users()">-</button>
                  <input type="text" class="text-center counter" v-model="number_Users">
                  <button class="billing-button px-2 mx-2 border-hidden" @click="increase_Users()">+</button>
                </div>
              </div>
              <div class="row pt-3" v-for="(user,index) in usersList" :key="index">
                <div class="col-12 text-center">
                  <div class="billing-color font-bold font-20">Total Cost {{ totalPrice }} {{ user.currency }} /
                    Month
                  </div>
                </div>
                <div class="col-12 text-center py-2">
                  <div v-if="isLoader" class="text-center">
                    <loader/>
                  </div>
                  <button v-else type="button" class="btn billing-button w-75 m-auto" @click="upgradeUsers(user.id)">
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end:modal-AddSeats-->
  <!--end:modals-->
</template>
<script>

import {retrieveBaseSubscriptions} from "@/apis";
import globalErrorHandler from "@/globalErrorHandler";
import {mapGetters} from "vuex";

export default {
  name: 'Plans',
  data() {
    return {
      can_attach_user: -1 <= this.GET_BASE_SUBSCRIPTION?.pricing?.features?.users_up_to >= 1,
      can_attach_brand: -1 <= this.GET_BASE_SUBSCRIPTION?.pricing?.features?.brands_up_to >= 1,
      cat_attach_storage: this.GET_BASE_SUBSCRIPTION?.plan === 'paid',
      errors: null,
      loading: false,
      message: '',
      number_Users: 1,
      users: 0,
      Brands: 0,
      price: 0,
      usersList: [],
      UpgradeError: false,
      isLoader: false,
      brands: 1,
      Brands_ARR: [],
      totalPrice: 0,
      userPrice: 0,
      upgradeBrandLaoder: false,
      totalBrand: 0,
      brandPrice: 0,
      storage: 1,
      storage_ARR: [],
      total_Storage: 0,
      storage_Price: 0,
      storageLoading: false,
    };
  },
  computed: {
    ...mapGetters(['GET_BASE_SUBSCRIPTION'])
  },
  methods: {
    openSeats() {
      document.getElementById('closeSeatsUpgrade').click();
    },
    openBrands() {
      document.getElementById('closeUpgradeBrands').click();
    },
    increase_Users() {
      this.number_Users += 1;
      this.totalPrice = this.userPrice * this.number_Users;
    },
    decrease_Users() {
      if (this.number_Users == 1) {
        return;
      }
      this.number_Users -= 1;
      this.totalPrice = this.userPrice * this.number_Users;
    },
    increase_Brands() {
      this.brands += 1;
      this.totalBrand = this.brandPrice * this.brands;
    },
    decrease_Brands() {
      if (this.brands == 1) {
        return;
      }
      this.brands -= 1;
      this.totalBrand = this.brandPrice * this.brands;
    },
    increase_Storage() {
      this.storage += 1;
      this.total_Storage = this.storage_Price * this.storage;
    },
    decrease_Storage() {
      if (this.storage == 1) {
        return;
      }
      this.storage -= 1;
      this.total_Storage = this.storage_Price * this.storage;
    }
  }
}
</script>