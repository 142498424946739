<template>
  <div class="form-group p-2">
    <div class="row pt-3">
      <div class="col">
        <label class="form-label">Change Image</label>
        <input type="file" class="form-control" @change="uploadImage($event)" accept=".png, .jpg, .jpeg">
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';

// Define the component name
defineOptions({
  name: 'Image'
});

// Define props
const props = defineProps({
  element: {
    required: true
  }
});

const uploadImage = (event) => {
  const file = event.target.files[0];
  const reader = new FileReader();
  reader.onload = (e) => {
    props.element.attr('href', e.target.result);
  };
  reader.readAsDataURL(file);
  event.target.value = null;
};
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}

.form-label {
  font-weight: bold;
}
</style>
