<template>
  <div class="container-fluid bgcustom pt-3 pb-5 pe-4 ps-4">
    <!-- Card Container -->
    <div class="card shadow rounded-5 hborder pe-0 ps-1 pt-0 pb-0">
      <div class="card-body pt-0">
        <!--begin:designs-->
        <div class="row justify-content-center pt-2">
          <div class="col col-sm-6 text-center">
            <div v-if="cdesignErorr" class="alert alert-danger br-radius p-1 mx-4"> {{ message }}</div>
            <div class="alert alert-danger p-1 mx-4 br-radius" v-if="dataError">{{ message }}</div>
          </div>
        </div>
        <!--begin:breadcrumb-->
        <div v-if="!still_working" class="row pt-2 pb-3">
          <div class="col ps-3">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item">
                  <router-link to="/"><img src="/assets/icons/back-arrow.svg" alt="icon"
                      class="icon-class-bg" /></router-link>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <!--end:breadcrumb-->
        <div v-if="!still_working" class="row pt-2">
          <div class="col-6 col-sm-2 col-xxl-1">
            <div class="space">UPDATE DESIGN</div>
            <div class="line-designs px-3"></div>
          </div>
          <div class="col-6 col-sm-10 d-none d-sm-block col-xxl-11 text-end pe-5">
            <div class="dropdown">
              <span class="mx-1 mouse" data-bs-toggle="modal" data-bs-target="#caption">Caption
                <img src="/assets/icons/caption.svg" alt="icon" class="icon-class" />
              </span> |
              <!-- <span class="mx-1 mouse" data-bs-toggle="modal" data-bs-target="#shareModal">Share
                <img src="/assets/icons/share.svg" alt="icon" class="icon-class"/>
              </span> | -->
              <span class="dropdown-toggle mouse" id="resize_id" data-bs-toggle="dropdown" aria-expanded="false">Resize
                <img src="/assets/icons/resize.svg" alt="icon" class="icon-class" />
              </span>
              <ul class="dropdown-menu" aria-labelledby="resize_id">
                <li class="dropdown-item mouse" v-for="(item, index) in items" :key="index"
                  @click="postResize(item.id)">
                  <div class="my-2">
                    <img :src="this.BASE_URL + item.icon" class="images-width-new mx-1" alt="">
                    {{ item.title }}
                  </div>

                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- <div v-if="still_working" class="row justify-content-center">
          <div class="col-auto">
            <div class="alert alert-info">
              <img src="/assets/images/Animation.gif" alt=""> Please wait, We still cooking your Soda ...
            </div>
          </div>
        </div> -->

        <div v-if="still_working" class="row justify-content-center">
          <div class="col-auto text-center">
            <div class="character">

              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="600" height="400"
                viewBox="0 0 1060 1133.4" style="enable-background:new 0 0 1060 1133.4;" xml:space="preserve">
                <g>
                  <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="74.2431" y1="585.801" x2="359.3106"
                    y2="585.801">
                    <stop offset="6.926126e-08" style="stop-color:#E26600" />
                    <stop offset="0.25" style="stop-color:#E74D20" />
                    <stop offset="0.5" style="stop-color:#ED3341" />
                    <stop offset="0.75" style="stop-color:#F21A61" />
                    <stop offset="1" style="stop-color:#F70081" />
                  </linearGradient>
                  <path style="fill:url(#SVGID_1_);" d="M354.5,586.4c0,18-3.3,36.1-9.7,53c-0.4,1.1-0.8,2.1-1.3,3.2l-1.3,3.1
		c-0.9,2.1-1.9,4.1-2.9,6.2c-1,2.1-2.1,4-3.1,6.1c-1.1,2-2.3,3.9-3.4,5.9c-4.8,7.8-10.3,15.1-16.6,21.9
		c-6.3,6.7-13.2,12.9-20.8,18.2c-1.9,1.3-3.8,2.6-5.8,3.8c-2,1.2-4,2.4-6,3.6c-2.1,1.1-4.1,2.2-6.2,3.2l-3.2,1.5
		c-1.1,0.5-2.1,1-3.2,1.4c-17.1,7.3-35.8,10.8-54.3,10.5c-9.3-0.2-18.5-1.3-27.5-3.4c-9-2.1-17.8-5-26.1-8.8
		c-16.8-7.5-31.9-18.3-44.6-31.3c-6.4-6.5-12.1-13.6-17.2-21.1c-5.1-7.5-9.4-15.6-13-23.9l-1.3-3.2c-0.4-1.1-0.9-2.1-1.2-3.2
		l-1.1-3.2c-0.4-1.1-0.8-2.1-1.1-3.2c-0.6-2.2-1.4-4.3-1.9-6.6c-0.5-2.2-1.2-4.4-1.6-6.6c-1.9-8.9-3.1-18-3.3-27.2
		c-0.4-18.3,2.6-36.9,9.4-54.2c6.7-17.3,17.1-33.1,30.2-46.4c6.5-6.6,13.7-12.7,21.4-17.9c7.7-5.3,15.9-9.8,24.5-13.4
		c17.2-7.3,36-11,54.6-11c18.7,0.1,37.4,3.9,54.5,11.3c17.1,7.4,32.5,18.5,45,32c6.3,6.8,11.8,14.1,16.6,21.9c1.1,2,2.3,3.9,3.4,5.9
		c1,2,2.2,4,3.1,6.1c0.9,2.1,2,4.1,2.9,6.2l1.3,3.2c0.4,1.1,0.9,2.1,1.3,3.2C351.3,550.3,354.6,568.4,354.5,586.4z M353.5,586.4
		c0-17.9-3.9-35.8-11.2-52c-0.4-1-1-2-1.4-3l-1.5-3c-0.5-1-1.1-2-1.6-2.9l-1.6-2.9c-1.1-1.9-2.3-3.8-3.5-5.6
		c-1.2-1.8-2.5-3.6-3.8-5.5c-5.2-7.1-11.1-13.7-17.5-19.7c-25.8-23.7-60-37.3-94.6-37c-17.2,0.1-34.4,3.4-50.4,9.9
		c-16,6.5-30.7,16.1-43.1,28.2c-24.8,24.2-39.9,58.3-40.8,93.5C82,604,85,621.8,91.6,638.2c6.6,16.5,16.6,31.6,29.3,44
		c12.7,12.4,28,22.1,44.4,28.3c8.2,3.1,16.7,5.4,25.4,6.8c8.6,1.5,17.3,2.1,26.1,1.9c17.4-0.3,34.6-3.8,50.7-10.4
		c16.1-6.5,31.1-15.9,43.9-27.8c6.4-6,12.3-12.6,17.5-19.7c1.3-1.8,2.6-3.6,3.8-5.5c1.2-1.9,2.4-3.7,3.5-5.6l1.6-2.9
		c0.5-1,1.1-1.9,1.6-2.9l1.5-3c0.5-1,1-2,1.4-3C349.7,622.2,353.5,604.3,353.5,586.4z" />

                  <linearGradient id="SVGID_00000085965794865783063370000003618859971393036436_"
                    gradientUnits="userSpaceOnUse" x1="4.8753" y1="786.5129" x2="113.3104" y2="786.5129">
                    <stop offset="6.926126e-08" style="stop-color:#E26600" />
                    <stop offset="0.25" style="stop-color:#E74D20" />
                    <stop offset="0.5" style="stop-color:#ED3341" />
                    <stop offset="0.75" style="stop-color:#F21A61" />
                    <stop offset="1" style="stop-color:#F70081" />
                  </linearGradient>
                  <path style="fill:url(#SVGID_00000085965794865783063370000003618859971393036436_);" d="M105.7,752.9c0.8,1.2,1.5,2.6,1.9,4
		c0.4,1.4,0.7,2.8,0.8,4.3c0.2,2.9-0.2,5.8-0.9,8.6c-0.7,2.8-1.7,5.5-2.9,8.1c-1.2,2.6-2.6,5.2-4.1,7.6
		c-6.2,9.8-14.3,18.3-23.7,25.2c-9.4,6.9-19.8,12.5-31.2,15.9c-5.7,1.6-11.8,2.6-18.1,2.2c-3.2-0.2-6.4-0.8-9.6-2.2
		c-1.6-0.7-3.1-1.6-4.6-2.6c-1.4-1.1-2.7-2.4-3.8-3.9c-1.1-1.5-1.9-3.2-2.5-4.9c-0.6-1.7-1-3.5-1.1-5.2c-0.3-3.5,0.1-6.8,0.9-10
		c1.6-6.3,4.4-11.9,7.7-17c6.7-10.2,15.7-18.5,25.6-25c2.5-1.6,5-3.1,7.7-4.5c2.6-1.4,5.3-2.6,8.1-3.7c5.5-2.2,11.3-3.8,17.1-4.8
		c2.9-0.5,5.9-0.8,8.9-0.8c3-0.1,5.9,0,8.9,0.5c2.9,0.5,5.8,1.2,8.5,2.6c1.3,0.6,2.6,1.5,3.7,2.4
		C103.9,750.6,104.9,751.7,105.7,752.9z M104.8,753.5c-0.8-1.1-1.8-2.1-2.9-2.9c-1.1-0.8-2.3-1.5-3.6-2c-2.5-1-5.3-1.4-8-1.4
		c-5.5-0.1-10.9,1.3-16.1,3.2c-2.6,0.9-5.1,2.1-7.5,3.3c-2.5,1.2-4.8,2.5-7.2,3.9c-2.3,1.4-4.6,2.8-6.9,4.3
		c-2.3,1.5-4.5,3.1-6.7,4.7c-4.4,3.2-8.5,6.7-12.4,10.4c-1.9,1.9-3.8,3.8-5.6,5.8c-1.8,2-3.5,4-5,6.1c-3.1,4.2-5.8,8.8-7.3,13.5
		c-0.7,2.3-1.1,4.7-1.1,6.9c0,1.1,0.2,2.1,0.5,3.1c0.3,1,0.7,1.9,1.2,2.7c0.6,0.8,1.2,1.6,2,2.3c0.8,0.6,1.7,1.3,2.7,1.7
		c2,1,4.4,1.6,6.9,1.8c5,0.5,10.4-0.1,15.7-1.3c10.5-2.5,20.7-7.3,30-13.4c4.7-3,9.1-6.4,13.3-10.1c4.2-3.7,8.1-7.7,11.6-12.1
		c1.7-2.2,3.3-4.6,4.6-7c1.3-2.5,2.5-5,3.3-7.7c0.8-2.7,1.2-5.5,1-8.3c-0.1-1.4-0.4-2.7-0.8-4.1
		C106.3,755.9,105.6,754.6,104.8,753.5z" />

                  <linearGradient id="SVGID_00000021093646447817929120000011166302720211931784_"
                    gradientUnits="userSpaceOnUse" x1="314.7291" y1="786.5129" x2="423.1642" y2="786.5129">
                    <stop offset="6.926126e-08" style="stop-color:#E26600" />
                    <stop offset="0.25" style="stop-color:#E74D20" />
                    <stop offset="0.5" style="stop-color:#ED3341" />
                    <stop offset="0.75" style="stop-color:#F21A61" />
                    <stop offset="1" style="stop-color:#F70081" />
                  </linearGradient>
                  <path style="fill:url(#SVGID_00000021093646447817929120000011166302720211931784_);" d="M323.2,753.5c-0.8,1.1-1.4,2.3-1.9,3.6
		c-0.4,1.3-0.7,2.7-0.8,4.1c-0.2,2.8,0.2,5.6,1,8.3c0.8,2.7,2,5.3,3.3,7.7c1.4,2.5,2.9,4.8,4.6,7c3.4,4.4,7.4,8.4,11.6,12.1
		c4.2,3.7,8.7,7,13.3,10.1c9.3,6.1,19.4,10.9,30,13.4c5.2,1.2,10.6,1.9,15.7,1.3c2.5-0.3,4.9-0.9,6.9-1.8c1-0.5,1.9-1.1,2.7-1.7
		c0.8-0.7,1.5-1.4,2-2.3c0.5-0.9,0.9-1.8,1.2-2.7c0.3-1,0.5-2,0.5-3.1c0.1-2.2-0.4-4.6-1.1-6.9c-1.5-4.7-4.1-9.3-7.3-13.5
		c-1.5-2.1-3.3-4.2-5-6.1c-1.8-2-3.7-3.9-5.6-5.8c-3.9-3.7-8-7.2-12.4-10.4c-2.2-1.6-4.4-3.2-6.7-4.7c-2.3-1.5-4.6-2.9-6.9-4.3
		c-2.3-1.4-4.7-2.7-7.2-3.9c-2.5-1.2-5-2.4-7.5-3.3c-5.1-1.9-10.6-3.3-16.1-3.2c-2.7,0.1-5.5,0.4-8,1.4c-1.3,0.5-2.5,1.2-3.6,2
		C325,751.4,324,752.4,323.2,753.5z M322.3,752.9c0.8-1.2,1.8-2.3,3-3.3c1.2-0.9,2.4-1.8,3.7-2.4c2.7-1.3,5.5-2.1,8.5-2.6
		c2.9-0.5,5.9-0.6,8.9-0.5c3,0.1,5.9,0.4,8.9,0.8c5.9,0.9,11.6,2.6,17.1,4.8c2.8,1.1,5.5,2.4,8.1,3.7c2.6,1.4,5.2,2.9,7.7,4.5
		c9.9,6.6,18.8,14.9,25.6,25c3.3,5.1,6.2,10.7,7.7,17c0.7,3.1,1.2,6.5,0.9,10c-0.1,1.7-0.5,3.5-1.1,5.2c-0.6,1.7-1.5,3.4-2.5,4.9
		c-1.1,1.5-2.4,2.8-3.8,3.9c-1.4,1.1-3,2-4.6,2.6c-3.2,1.4-6.4,1.9-9.6,2.2c-6.3,0.4-12.4-0.7-18.1-2.2c-11.5-3.4-21.9-9-31.2-15.9
		c-9.3-6.9-17.5-15.4-23.7-25.2c-1.5-2.4-2.9-5-4.1-7.6c-1.2-2.6-2.2-5.3-2.9-8.1c-0.7-2.8-1.1-5.7-0.9-8.6c0.1-1.5,0.4-2.9,0.8-4.3
		C320.9,755.5,321.5,754.1,322.3,752.9z" />

                  <linearGradient id="SVGID_00000136371481770742028760000016292744964855999914_"
                    gradientUnits="userSpaceOnUse" x1="63.3286" y1="931.9135" x2="351.2183" y2="931.9135">
                    <stop offset="6.926126e-08" style="stop-color:#E26600" />
                    <stop offset="0.25" style="stop-color:#E74D20" />
                    <stop offset="0.5" style="stop-color:#ED3341" />
                    <stop offset="0.75" style="stop-color:#F21A61" />
                    <stop offset="1" style="stop-color:#F70081" />
                  </linearGradient>
                  <path style="fill:url(#SVGID_00000136371481770742028760000016292744964855999914_);" d="M213.4,1129.4c-5.5,0-11-0.9-16.3-2.3
		c-5.3-1.4-10.5-3.4-15.5-5.8c-9.9-4.8-19.1-11-27.6-18c-17-14.1-31.6-30.9-44-49.2c-12.4-18.3-22.6-38.2-30.2-59.1
		c-3.8-10.4-6.9-21.1-9.4-32c-1.2-5.4-2.3-10.9-3.1-16.4c-0.5-2.7-0.8-5.5-1.1-8.3c-0.2-1.4-0.3-2.8-0.4-4.2
		c-0.1-1.4-0.3-2.8-0.4-4.2c-1.7-22.2-0.2-44.8,5.7-66.3l0.5-2l0.6-2l1.2-4l1.4-3.9l0.7-2l0.8-1.9l1.5-3.9l1.7-3.8
		c0.3-0.6,0.5-1.3,0.8-1.9l0.9-1.9c0.6-1.2,1.2-2.5,1.8-3.7l2-3.7c0.3-0.6,0.6-1.2,1-1.8l1.1-1.8c0.7-1.2,1.4-2.4,2.1-3.6l2.3-3.5
		c0.7-1.2,1.5-2.3,2.3-3.4l2.4-3.4l2.5-3.3l1.2-1.7l1.3-1.6c6.9-8.6,14.5-16.7,22.6-24.1c8.2-7.4,16.9-14.2,26.2-20.2
		c2.3-1.5,4.6-3,7-4.3c1.2-0.7,2.4-1.4,3.6-2.1l3.7-2l1.8-1c0.6-0.3,1.2-0.6,1.9-0.9l3.7-1.8c1.2-0.6,2.5-1.1,3.8-1.7
		c1.3-0.5,2.5-1.2,3.8-1.7c10.3-4.1,21-7.5,32-9.2c2.8-0.4,5.5-0.8,8.3-1c1.4-0.1,2.8-0.3,4.2-0.3l4.2-0.1c1.4-0.1,2.8,0,4.2,0
		c1.4,0,2.8,0.1,4.2,0.1l4.2,0.4l2.1,0.2l2.1,0.3l4.2,0.7l4.2,0.9l2.1,0.5c0.7,0.1,1.4,0.4,2.1,0.6l4.1,1.2c5.4,1.7,10.7,4,15.7,6.6
		c10.1,5.3,19.2,12.5,27,20.9c7.8,8.3,14.3,17.7,19.7,27.6c5.4,9.9,9.8,20.3,13.3,30.9c3.6,10.6,6.3,21.4,8.5,32.4
		c2.2,10.9,3.7,22,4.7,33c0.5,5.5,0.8,11.1,1,16.7c0.2,5.6,0.2,11.2,0,16.8c-0.5,11.2-1.7,22.3-3.6,33.3c-1.9,11-4.5,21.8-7.7,32.5
		c-6.4,21.3-15.4,41.8-26.6,60.9c-11.3,19-24.9,36.8-41.2,51.7c-4.1,3.7-8.4,7.1-12.9,10.3c-4.5,3.2-9.2,6-14.2,8.5
		c-4.9,2.5-10.1,4.5-15.4,5.8C224.4,1128.7,218.9,1129.4,213.4,1129.4z M213.4,1128.3l2,0l1,0l1-0.1l2-0.2l2-0.3l1-0.1l1-0.2l2-0.4
		l2-0.5c0.7-0.1,1.3-0.3,2-0.5c5.2-1.5,10.3-3.5,15-6.1c9.6-5.1,18.2-11.9,25.8-19.5c3.8-3.8,7.5-7.8,10.9-12
		c3.4-4.2,6.7-8.5,9.7-13c6.1-8.9,11.6-18.3,16.4-27.9c9.7-19.3,17.3-39.6,22.9-60.3c5.6-20.7,9.2-42,9.8-63.4
		c0.1-2.7,0.1-5.3,0.1-8c0-2.7-0.1-5.3-0.2-8c-0.2-5.4-0.5-10.7-1-16.1c-0.9-10.7-2.3-21.4-4.2-31.9c-1.9-10.5-4.5-21-7.7-31.1
		c-3.2-10.2-7.2-20.1-12.1-29.5c-4.9-9.4-10.8-18.2-17.8-26c-7-7.8-15.1-14.5-24.2-19.7c-9.1-5.2-19.1-8.8-29.4-10.8l-1.9-0.4
		l-2-0.3l-3.9-0.5c-1.3-0.1-2.6-0.2-4-0.3c-1.3-0.1-2.6-0.2-4-0.2l-4-0.1c-1.3,0-2.7,0.1-4,0.1c-2.7,0.1-5.3,0.4-8,0.6
		c-1.3,0.1-2.6,0.4-4,0.5c-1.3,0.2-2.6,0.4-4,0.6l-3.9,0.8c-0.7,0.1-1.3,0.3-2,0.4l-2,0.5l-3.9,1l-3.9,1.2c-2.6,0.7-5.1,1.7-7.6,2.6
		c-1.3,0.4-2.5,1-3.8,1.5c-1.2,0.5-2.5,1-3.7,1.5l-3.7,1.7c-0.6,0.3-1.2,0.5-1.8,0.8l-1.8,0.9l-3.6,1.8c-1.2,0.6-2.4,1.3-3.6,2
		c-2.4,1.3-4.7,2.7-7,4.1c-1.2,0.7-2.3,1.4-3.4,2.2l-3.4,2.2l-3.3,2.4c-1.1,0.8-2.2,1.6-3.3,2.4c-2.1,1.7-4.3,3.3-6.4,5
		c-1,0.9-2.1,1.7-3.1,2.6l-3,2.7c-8.1,7.2-15.5,15.2-22.1,23.8c-6.6,8.6-12.3,17.8-16.9,27.6c-0.6,1.2-1.1,2.5-1.6,3.7l-0.8,1.8
		c-0.3,0.6-0.5,1.3-0.7,1.9l-1.5,3.8l-1.3,3.8l-0.7,1.9l-0.6,1.9l-1.1,3.9l-1,3.9l-0.5,2l-0.4,2l-0.8,3.9l-0.7,4l-0.3,2l-0.3,2
		l-0.6,4l-0.4,4l-0.2,2l-0.1,2l-0.3,4l-0.2,4c-0.4,10.7,0.2,21.5,1.3,32.1c1.2,10.7,3.1,21.2,5.5,31.7c2.5,10.4,5.5,20.8,9,30.9
		c1.8,5.1,3.6,10.1,5.7,15.1c1,2.5,2.1,5,3.1,7.5c1.1,2.5,2.2,4.9,3.3,7.4l1.7,3.7l1.8,3.6c1.2,2.4,2.4,4.8,3.6,7.2
		c2.5,4.8,5.1,9.5,7.9,14.1c1.4,2.3,2.8,4.6,4.2,6.9c1.5,2.3,2.9,4.6,4.4,6.8c3,4.5,6.2,8.9,9.5,13.2c3.3,4.3,6.8,8.4,10.4,12.4
		c3.6,4,7.5,7.8,11.5,11.5c4,3.7,8.2,7.1,12.6,10.3c4.4,3.2,9,6,13.9,8.5c4.8,2.5,9.9,4.5,15.1,6
		C202.6,1127.4,208,1128.2,213.4,1128.3z" />
                  <path style="opacity:0.3;fill:#ADADAD;"
                    d="M225.1,465.1c6.8-8.6,43.2,5.4,68.2,25.6c8.8,7.1,38.7,31.9,46,74.1
		c6.8,39.3-9,76.4-14.8,75.8c-4.8-0.4,1.6-26.1-13.3-56.2c-10.6-21.3-21.7-23.2-45.2-50.3C259.6,526.7,216,476.5,225.1,465.1z" />
                  <path style="opacity:0.3;fill:#ADADAD;" d="M208.2,771.2c5.7-7.7,30.7-1,48.5,9.4c55.2,32.1,60.3,110.7,60.8,119.8
		c4.2,82.3-51.6,147.4-59.3,144.3c-5.1-2.1,12.7-33.6,12.4-91.1c-0.1-19.6-0.8-62.3-21.6-108.1C228.6,800.6,201.5,780.3,208.2,771.2
		z" />
                  <path style="fill:#D1D1D1;" d="M302.9,601.1c-24.4,4.2-53.9,7.4-87.2,7.3c-32.7,0-61.6-3.2-85.7-7.3c-1.5,0-9.4-0.2-15.8-6.6
		c-4.1-4.1-6.6-9.7-6.6-15.8v-4.8c0-12.3,10.1-22.4,22.4-22.4c23.8,4.2,52.5,7.4,84.9,7.5c33.8,0.1,63.5-3.2,88-7.5
		c1.5,0,9.4,0.2,15.8,6.6c4.1,4.1,6.6,9.7,6.6,15.8v4.8C325.3,591,315.3,601.1,302.9,601.1z" />

                  <linearGradient id="SVGID_00000023974247006539446830000000117714904864813713_"
                    gradientUnits="userSpaceOnUse" x1="161.2582" y1="582.3982" x2="199.2431" y2="582.3982">
                    <stop offset="6.926126e-08" style="stop-color:#E26600" />
                    <stop offset="0.25" style="stop-color:#E74D20" />
                    <stop offset="0.5" style="stop-color:#ED3341" />
                    <stop offset="0.75" style="stop-color:#F21A61" />
                    <stop offset="1" style="stop-color:#F70081" />
                  </linearGradient>
                  <path style="fill:url(#SVGID_00000023974247006539446830000000117714904864813713_);" d="M180.3,563.4c-10.5,0-19,8.5-19,19
		c0,10.5,8.5,19,19,19c10.5,0,19-8.5,19-19C199.2,571.9,190.7,563.4,180.3,563.4z M193.9,587.5c-0.1,0-0.3,0-0.4-0.1
		c-0.8-0.2-1.3-1.1-1.1-1.9c1.7-6.7-2.3-13.5-8.9-15.3c-0.8-0.2-1.3-1.1-1.1-1.9c0.2-0.8,1.1-1.3,1.9-1.1c8.3,2.2,13.3,10.7,11.2,19
		C195.2,587,194.5,587.5,193.9,587.5z" />

                  <linearGradient id="SVGID_00000042720382709194366590000008003244346788009644_"
                    gradientUnits="userSpaceOnUse" x1="241.4925" y1="582.3982" x2="279.4774" y2="582.3982">
                    <stop offset="6.926126e-08" style="stop-color:#E26600" />
                    <stop offset="0.25" style="stop-color:#E74D20" />
                    <stop offset="0.5" style="stop-color:#ED3341" />
                    <stop offset="0.75" style="stop-color:#F21A61" />
                    <stop offset="1" style="stop-color:#F70081" />
                  </linearGradient>
                  <path style="fill:url(#SVGID_00000042720382709194366590000008003244346788009644_);" d="M260.5,563.4c-10.5,0-19,8.5-19,19
		c0,10.5,8.5,19,19,19c10.5,0,19-8.5,19-19C279.5,571.9,271,563.4,260.5,563.4z M274.1,587.5c-0.1,0-0.3,0-0.4-0.1
		c-0.8-0.2-1.3-1.1-1.1-1.9c1.7-6.7-2.3-13.5-8.9-15.3c-0.8-0.2-1.3-1.1-1.1-1.9c0.2-0.8,1.1-1.3,1.9-1.1c8.3,2.2,13.3,10.7,11.2,19
		C275.4,587,274.8,587.5,274.1,587.5z" />
                  <g>

                    <linearGradient id="SVGID_00000145021127118051557290000011089185579722624415_"
                      gradientUnits="userSpaceOnUse" x1="188.7935" y1="647.3406" x2="246.9999" y2="647.3406">
                      <stop offset="6.926126e-08" style="stop-color:#E26600" />
                      <stop offset="0.25" style="stop-color:#E74D20" />
                      <stop offset="0.5" style="stop-color:#ED3341" />
                      <stop offset="0.75" style="stop-color:#F21A61" />
                      <stop offset="1" style="stop-color:#F70081" />
                    </linearGradient>
                    <path style="fill:url(#SVGID_00000145021127118051557290000011089185579722624415_);"
                      d="M217.9,659.4c-11.3,0-21.9-5.8-28.2-15.7
			c-1.6-2.5-0.9-5.7,1.6-7.3c2.5-1.6,5.7-0.9,7.3,1.6c4.8,7.4,12.9,11.5,21.3,10.7c10.9-1,16.7-9.9,17.3-10.9
			c1.5-2.5,4.8-3.3,7.3-1.7c2.5,1.5,3.3,4.8,1.7,7.3c-1.5,2.4-9.7,14.5-25.4,15.9C219.8,659.4,218.9,659.4,217.9,659.4z" />
                  </g>
                </g>
                <image style="display:none;overflow:visible;" width="326" height="285" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUYAAAEdCAYAAABwns7EAAAKwklEQVR4nO3deahmBR3H4e84OmHj
QolhLmmbGymFbbZYaQtUVIJKlkUbY5RBtpnQgm24lPRH0kJ7RtE2U9AGCqmREqntOmnk2oJli3f+
aNQmjp253H7OvMu97733vOd9HhgYZs77vuf87uXDed9z3nPWzM3NZQfWJXlJ++fYJPu3/wYwzZrg
3Zbk2iQbk3w7yda6PTsK44lJzk/yKD9+oOduTHJWkm8t3MxdFvx9bZLz2gVEEZgFTeu+2bZv7fbt
3XXBhn8oyTv8KgAzaHv7mr3H+bfSJyX5ut8GYMadnOQbTRibgyq/T3LgrE8EmHm3J3nELm0hRREg
OSDJKbu0p+QA8D8vacL4BMMAmPf4Joz7mQfAvIc2YXyAeQDMW7eLWQD8P2EEKIQRoBBGgEIYAQph
BCiEEaAQRoBCGAEKYQQohBGgEEaAQhgBCmEEKIQRoBBGgEIYAQphBCiEEaAQRoBCGAEKYQQohBGg
EEaAQhgBCmEEKIQRoBBGgEIYAQphBCiEEaAQRoBCGAEKYQQohBGgEEaAQhgBCmEEKIQRoBBGgEIY
AQphBCiEEaAQRoBCGAEKYQQohBGgEEaAQhgBCmEEKIQRoBBGgEIYAQphBCiEEaAQRoBCGAEKYQQo
hBGgEEaAQhgBCmEEKIQRoBBGgEIYAQphBCiEEaAQRoBCGAEKYQQohBGgEEaAQhgBCmEEKIQRoBBG
gEIYAQphBCiEEaAQRoBCGAEKYQQohBGgEEaAQhgBCmEEKIQRoBBGgEIYAQphBCiEEaAQRoBCGAEK
YQQohBGgEEaAQhgBCmEEKIQRoBBGgEIYAQphBCiEEaAQRoBCGAEKYQQohBGgEEaAQhgBCmEEKIQR
oBBGgEIYAQphBCiEEaAQRoBCGAEKYQQohBGgEEaAQhgBCmEEKIQRoBBGgEIYAQphBCiEEaAQRoBC
GAEKYQQohBGgEEaAQhgBCmEEKIQRoBBGgEIYAQphBCiEEaAQRoBCGAEKYQQohBGgEEaAQhgBCmEE
KIQRoBBGgEIYAQphBCiEEaBowniXoQDM29qE8U/mATDvT00Yf2keAPN+1oRxk3kAzNu0Zm5ubl2S
3yc50FyAGXd7kkc2e4xbk7xl1qcBkOTMJP/efrrO15NcYCrADLugbeH/ncd4dpKP+a0AZtBFbQPv
szCM9yZ5U5KTktzoNwOYAU3rTk5yRtvA+zQHX3a06evahU9MckySA5Ls5rcEmHJ3twdYrk6ysX3r
vLVu0s7CCDCzfFcaoFizbds2MwFYwB4jQCGMAMWuBgJUW7Zs6f1M1q9fv9P/E0ZgJTWn/R2d5PD2
z0FJ9kqyZ3vazL+S3JHk5iTXJbkmyZ9X+ickjMBy2yfJS5O8KMnTk+w+5uvdkOT7Sb6a5MqV+Gk5
Kg3cz4TeSj8lyduTvGCCXxDZnOSTST7VrOZSnmjQW2lhBO5niWF8QpLzkjxrGSfbvN3+cJKP7uib
K6MYFEZHpYFJeXCSTyS5apmj2Ni3je8vkhw/6ScXRmASnpzk2iSnr3BXmgM4lyQ5d5LHTIQRWKrX
J7k8ycNWaZJrkpyV5LL2QM+SCSOwFOck+XhHrr7VHOz5cZKDl/pEwggs1keSvKdj02veWl+x1HtY
CSOwGO/s8L2impPGv5dk78U+gTAC43pekg92fGpHJflakrWLebAwAuPYP8kXp6Qdz114H5dxCCMw
juYbJw+Zook1n4E+ZtwHCSMwqhe3X++bJru1dwBcM846CyMwiubk6QundFLHtXc/HZkwAqN4WZJH
TPGk3jXOXqMwAsOsWexBjA5prgH5/FFXRxiBYY5tT5yedq8Zdf2FERjmlT2Z0AvbKwANJYzAIGva
o9F9sG7Ut9PCCAxyZJL9ejShka7dKIzAIMt9wdmV9oxRXk8YgUEe27PpPDzJHsMWEkZgkMN6Np3m
M9NHDVtIGIFBlnRdw44aeqK6MAKDTORWAR2z57DVEUZgkKERmULCCDAuYQQGuauH0xm6TcIIDPK3
Hk5HGIElua2H4/vDsAWEERhkc8+msy3JDcMWEkZgkJ/3bDrN3uLcsIWEERjkRz2bzuWjLCSMwCC/
SfKXHk3o0lEWEkZgkOYzuU09mdDWJN8bZUFhBIb5Uk8m9N0kd46yoDACw/wkyfU9mNLnRl1QGIFh
mrfT5035lDa3e4wjEUZgFBcnuWmKJ3VOkv+MurAwAqO4J8mZUzqpK5J8dZwHCCMwqk3jvB3tiCbo
b2g/DhiZMALj2JDkjima2LuT/HrcBwkjMI4/JnnFuHtgq+QHiz1oJIzAuH6Y5G0dn9qvkrx0sQEX
RmAxLkxybkcnd2uS5yf552KfQBiBxTo7yfs6Nr3mRPSnL/U6ksIILMV7k5yR5O4OTLH5hs7Tkty8
1CcSRmCpLkpyXJJbVmmSzeeI5yd55qRuxSCMwCRcleRxST41zjdMJqD5qt9zkpw1yb1WYQQmpbly
zelJjk1y2TJP9a/tZ5xHj3qNxXEIIzBpP23f1j61/bbMJD9/vDHJW5Mc0h4V37ocP70127ZNw3ma
wErasmXLJF9t3ySnJHlRe8R49zEff0N7svZXklw5qZVav379Tv9PGIH7mXAYF9qtfft7eJIjkhyY
5EFJHpjk3iT/aL9yeEt7W4Vrkvx5OVZEGIGxLGMYO0MYAcbg4AtAIYwAhTACFMIIUAgjQCGMAIUw
AhTCCFAII0AhjACFMAIUwghQ7Gog0E/TcIWcQVe4WU3CCOzIUUlOSPKUJIcmOSjJHu1yc+29m3/X
3pnv0vYG973hsmPQU4vYY2zCtyHJa5McOeZjf5vkM+3NsOZGfVBX9xiFEXpqjDA27xzfmOTdSfZZ
4jSa25e+v72l6j3DFhZGYEWNGMZHt/dSOWbC63Z1klPb+7XsVFfD6Kg0zK4XJvnZMkQx7XNe3b7G
1BFGmE0vT7IxyV7LuPV7tq9x2rRNWBhh9jS3Mf3CCp2V0rzG59vXnBrCCLPlsCQXJ1m7glu9tn3N
w6Zl0sIIs6PZe/ty+xZ3pe3ZvvZUnDstjDA73rhMB1pG1bz2GdMwbafrQE+V03Wak7dvmsB5ikvV
nOd4yPaTwJ2uA6ymDR2IYtp12NCB9RhIGGE2vLZDW9mlddkhYYT+O3oR331eTke269RZwgj9d3wH
t/CEDqzDTgkj9N+TO7iFXVynecII/XdEB7fw8A6sw04JI/TfAR3cwi6u0zxhhP5bjW+6DNPFdZon
jACFMEL/3dXBLeziOs0TRui/2zu4hV1cp3nCCP13fQe3sIvrNE8Yof+u6uAWdnGd5gkj9N+lHdzC
Lq7TPGGE/vtle9/nrvhtu06dJYwwGz7boa3s0rrskAvVQk919EK1dyY52IVqgS5oQvSBDqzH+7dH
scvsMUJPlT3GtDeiumoV7/tybZInJrln+z/YYwRW2z3tjfZX41snzV7iqQuj2GXCCLNlc5LTkty7
glt9bxvkzdMyaWGE2fOdJK9aoTg2r/Hq9jWnhjDCbLo4yUnLfCCkee6Tk3xp2iYsjDC7NrUHYq5Z
hglc0z73xmmcrjDCbPtdkicleXOSv09gEn9vn+tJ7XNPJafrQE/t4HSdYfZO8rr2vs/j3ifmuiSf
SfLpJP8c9UFdPV1HGKGnFhHGhY5K8uwkxyY5NMlBC25H0Jzuc2u7R3hlkkuS/GoxL9LJMCb5L+Cy
gCTfAOB1AAAAAElFTkSuQmCC" transform="matrix(1 0 0 1 29 448.8509)">
                </image>
                <circle style="fill:#F4F4F4;" cx="426.8" cy="445.7" r="37.4" />
                <circle style="fill:#F4F4F4;" cx="393.7" cy="533.4" r="25.9" />
                <rect x="0" y="70" width="1244" height="216" style="fill:#F4F4F4;" />

                <!-- Centered Text -->
                <text x="622" y="180" text-anchor="middle" dominant-baseline="middle"
                  style="font-family: 'Arimo'; font-size: 35px; font-weight: bold; text-size-adjust: auto; "
                  fill="black">
                  {{ currentMessage }}
                </text>

              </svg>


            </div>
          </div>
        </div>
        <div v-else class="row">
          <div class="col">
          </div>
          <!-- Social Share Modal -->
          <div class="row">
            <div class="col">
              <div class="modal fade" id="shareModal" tabindex="-1" aria-labelledby="shareModalLabel" aria-hidden="true"
                ref="shareModal">
                <div class="modal-dialog modal-l">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="shareModalLabel">Share on the social media</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col d-flex justify-content-around">
                          <img src="/assets/icons/facebook.svg" alt="Facebook" class="share-social-icon">
                          <img src="/assets/icons/instagram.svg" alt="Instagram" class="share-social-icon">
                          <img src="/assets/icons/snapchat.svg" alt="Snapchat" class="share-social-icon">
                          <img src="/assets/icons/linkedin.svg" alt="LinkedIn" class="share-social-icon">
                          <img src="/assets/icons/twitter.svg" alt="Twitter | X" class="share-social-icon">
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col">

                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn backgroundButton" data-bs-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6 col-md-8 col-xxl-4 updesingl">
              <div class="card border-hidden">
                <div class="card-body">

                  <form class="p-2" @submit.prevent="submitForm()">
                    <div class="row">
                      <div class="col-6 col-sm-3 col-md-6">
                        <label for="" class="font-14">Brands</label>
                        <select class="form-select font-12 bg-Gray" @change="chagefiled" v-model="brand">
                          <option value="0">Select Brand</option>
                          <option v-for="(val, index) in row.brands" :key="index" :value="val.id">{{ val.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-6 col-sm-3 col-md-6">
                        <label for="" class="font-14"> Category</label>
                        <select name="" class=" bg-Gray form-select font-12" @change="chagefiled" v-model="types" id="">
                          <option value="">Select Category</option>
                          <option v-for="(val, index) in row.temp_type" :key="index" :value="val.id">{{ val.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-12 col-sm-3 col-md-6">
                        <label for="" class="font-14">Content Tones</label>
                        <select name="" v-model="tones" @change="chagefiled" class="bg-Gray form-select font-12" id="">
                          <option value="0">Select Tones</option>
                          <option v-for="(val, index) in row.tones" :key="index" :value="val.id">{{ val.title }}
                          </option>
                        </select>
                      </div>
                      <div class="col-12 col-sm-3 col-md-6">
                        <label for="" class="font-14">Language</label>
                        <select name="" v-model="language" @change="chagefiled" class="form-select bg-Gray font-12"
                          id="">
                          <option value="0">Select Language</option>
                          <option v-for="(val, index) in row.output_languages" :key="index" :value="val.id">{{
                            val.name
                            }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <!-- <div class="row pt-2">
                      <div class="col bg-Gray">
                        <div class="p-2 pt-0">
                          <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                              <div class="form-check form-switch font-12 space pt-4 text-center text-md-start">
                                <input class="form-check-input" @change="chagefiled" v-model="buttonPrice"
                                       type="checkbox" id="price" default>
                                <label class="form-check-label" for="price">Include price</label>
                              </div>
                            </div>
                            <div class="col-12 col-md-12 col-lg-6 col-sm-12">
                              <label for="" class="font-12">Price</label>
                              <div class="input-group">
                                <select id="" v-model="currency" class="form-select p-0 br-color ps-1"
                                        :disabled="!buttonPrice">
                                  <option v-for="(val, index) in row.currencies" :key="index" :value="val.id">{{
                                      val.short_name
                                    }}
                                  </option>
                                </select>
                                <input type="text" :disabled="!buttonPrice"
                                       class="form-control p-0 w-20 br-color text-center" v-model="includePrice"
                                       aria-label="Text input with dropdown button">
                              </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                              <div class="form-check form-switch pt-4 font-12 space text-center text-md-start">
                                <input class="form-check-input" type="checkbox" @change="chagefiled"
                                       v-model="buttonSale" id="sale" default>
                                <label class="form-check-label" for="sale">Include Sale</label>
                              </div>
                            </div>
                            <div class="col-12 col-md-12 col-lg-6 col-sm-12">
                              <label for="" class="font-12">Percentage</label>
                              <div class="input-group">
                                <span
                                  :class="(buttonSale) ? 'input-group-text bg-white p-0 br-color ps-2' : 'input-group-text bg-feature p-0 br-color ps-2'">%</span>
                                <input type="text" class="form-control p-0 br-left br-color text-center"
                                       v-model="includeSale" aria-label="Text input with dropdown button"
                                       :disabled="!buttonSale">
                              </div>
                            </div>
                          </div>
                          <div class="row pt-2">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 pt-4 text-center text-md-start">
                              <div class="form-check form-switch font-12">
                                <input class="form-check-input" type="checkbox" @change="chagefiled"
                                       v-model="buttonBefore_After" id="priceBA" default>
                                <label class="form-check-label" for="priceBA">Include Price Before & After</label>
                              </div>
                            </div>
                            <div class="col-12 col-md-12 col-lg-2 col-sm-12">
                              <label for="" class="font-12">Currency</label>
                              <select id="" v-model="currency" class="form-select p-0 br-color ps-2"
                                      :disabled="!buttonBefore_After">
                                <option v-for="(item, index) in row.currencies" :key="index" :value="item.id">{{
                                    item.short_name
                                  }}
                                </option>
                              </select>
                            </div>
                            <div class="col-12 col-md-12 col-lg-2 col-sm-12">
                              <label for="" class="font-12 space">Price Before</label>
                              <input type="text" v-model="priceBefore" class="form-control p-0 br-color text-center"
                                     :disabled="!buttonBefore_After">
                            </div>
                            <div class="col-12 col-md-12 col-lg-2 col-sm-12">
                              <label for="" class="font-12 space">Price After</label>
                              <input type="text" v-model="priceAfter" class="form-control p-0 br-color text-center"
                                     :disabled="!buttonBefore_After">
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12 col-md-12 col-lg-6 col-sm-12">
                              <div class="form-check form-switch font-12 space pt-4 text-center text-md-start">

                                <input class="form-check-input" type="checkbox" @change="chagefiled"
                                       v-model="buttonOffer" id="offer" default>
                                <label class="form-check-label" for="offer">Include Offer</label>
                              </div>
                            </div>
                            <div class="col-12 col-md-12 col-lg-6 col-sm-12">
                              <label for="" class="font-12">What Is Your Offer</label>
                              <input type="text" class="form-control py-0 px-3 br-color" @change="chagefiled"
                                     v-model="includeOffer" :disabled="!buttonOffer"
                                     aria-label="Text input with dropdown button">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->


                    <div class="row pt-2">
                      <div class="col-12 col-sm-12 col-md-12">
                        <label for="" class="font-14">Describe Your Design ?</label>
                        <textarea name="" @change="chagefiled" v-model="topic"
                          class="form-control border-hidden bg-Gray" id="" rows="8">
                        </textarea>
                        <div class="error-feedback red_color" v-if="V$.topic.$error">
                          {{ V$.topic.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 col-sm-4 col-md-3 pt-3 imagebox" v-for="(x, index) in imagesView" :key="index">
                        <div class="image-container-box position-relative">
                          <img class="img-fluid-box" :src="BASE_URL + x" alt="Image">
                          <div class="overlay d-flex justify-content-center align-items-center">
                            <div class="text-center">
                              <span class="action-text" @click="changeImage(index)">Change Image</span>
                              <br />
                              <a @click.prevent="deleteImage(index)" class="delete-icon btn">
                                <i class="fa fa-trash-can"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr class="m-0 main-button">
                    <div class="row">
                      <div class="col">
                        <div class="card border-hidden bg-Gray">
                          <div class="card-body">
                            <h6 class="card-title main-image">Main Image /s</h6>
                            <div class="row">

                              <div class="col-12 col-sm-3 col-md-4 col-xxl-4 pb-2">
                                <div class="main-button" id="openLibrary" @click="getImagelist()" data-bs-toggle="modal"
                                  data-bs-target="#libraryModal">
                                  <i class="fa-solid fa-plus payment-color"></i> Add Images /s
                                </div>
                              </div>

                              <div class="col-12 col-sm-3 col-md-4 col-xxl-4 pb-2">
                                <div class="main-button" data-bs-toggle="modal" data-bs-target="#imageAI">
                                  <i class="fa-solid fa-plus payment-color"></i> Generate Image
                                </div>
                              </div>
                              <div v-if="isLoading">
                                <loader />
                              </div>
                              <div v-else class="col-12 col-md-4 text-end col-sm-9 ">
                                <button class="btn btn-sm btn-generate" type="submit" :disabled="!isActive">
                                  <img src="/assets/images/Vector.png" class="images-width" alt="">
                                  RE GENERATE
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- SAWAF -->
            <!-- Parent Container -->
            <div class="pt-2 updesingl bgcolor pe-0">
              <!-- Action Buttons for Mobile -->
              <div class="d-flex d-sm-none justify-content-end mb-2">
                <div class="dropdown">
                  <span class="mx-1 mouse" data-bs-toggle="modal" data-bs-target="#caption">Caption
                    <img src="/assets/icons/caption.svg" alt="icon" class="icon-class" />
                  </span> |
                  <!-- <span class="mx-1 mouse" data-bs-toggle="modal" data-bs-target="#shareModal">Share
                    <img src="/assets/icons/share.svg" alt="icon" class="icon-class"/>
                  </span> | -->
                  <span class="dropdown-toggle mouse" id="resize_id" data-bs-toggle="dropdown"
                    aria-expanded="false">Resize
                    <img src="/assets/icons/resize.svg" alt="icon" class="icon-class" />
                  </span>
                  <ul class="dropdown-menu" aria-labelledby="resize_id">
                    <li class="dropdown-item mouse" v-for="(item, index) in items" :key="index"
                      @click="postResize(item.id)">
                      {{ item.title }}
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Loading Indicator -->
              <div v-if="tempLoading" class="d-flex justify-content-center align-items-center"
                style="min-height: 200px;">
                <loadersoda />
              </div>

              <!-- Images Grid with Scrollable Container -->
              <div v-else class="bg-gray pt-0 ratio-4x3 ratio pb-0 mt-2 mb-3 mb-sm-4 mb-lg-4 mb-md-4 pe-1">
                <!-- Scrollable Container -->
                <div class="row overflow-auto scroll-container" ref="scrollContainer"
                  style="max-height: calc(2 * (var(--image-height) + 1rem));">
                  <div class="col-6 pb-3" v-for="(image, index) in imagesList" :key="index">
                    <div class="position-relative">
                      <img :src="`${BASE_URL}${image}?v=${timestamp}`" loading="lazy" :id="'vue-image-' + index"
                        class="img-fluid-preview image-item" alt="" @click="selected_image(index)" />
                      <!-- Optional: Overlay or Checkmark for Selected Images -->
                      <div v-if="currentImageIndex === index" class="position-absolute top-0 start-0 w-100 h-100"
                        style="background: rgba(0, 0, 0, 0.5);"></div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Action Buttons when an Image is Selected -->
              <div class="row pt-2 justify-content-end mb-5 pe-4" ref="actionButtons">
                <div class="col-auto">
                  <button type="button" class="btn bg-white line-designs text-dark" @click.prevent="checkDownload"
                    :disabled="!isSelected">
                    Download <img src="/assets/icons/download.svg" alt="icon" class="icon-class" />
                  </button>
                </div>
                <div class="col-auto">
                  <div class="dropdown">
                    <button class="btn btn-generate dropdown-toggle" type="button" id="customizeDropdown"
                      data-bs-toggle="dropdown" aria-expanded="false" :disabled="!isSelected">
                      Customize Design
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="customizeDropdown">
                      <li>
                        <button class="dropdown-item" :disabled="!isSelected" @click="openEditor">
                          <img src="/assets/icons/photo-editing.svg" alt="icon" class="icon-class" /> Editor
                        </button>
                      </li>
                      <li>
                        <button class="dropdown-item" @click="openProEditor" :disabled="!isSelected">
                          <img src="/assets/icons/illustration.svg" alt="icon" class="icon-class" /> Pro Editor
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-auto">
                  <!-- Preview Button -->
                  <button type="button" class="btn btn-primary" @click="openPreviewModal" :disabled="!isSelected">
                    Preview
                  </button>
                </div>
              </div>

              <!-- Preview Modal -->
              <div class="modal fade" id="previewModal" tabindex="-1" aria-labelledby="previewModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="previewModalLabel">Preview Image</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body text-center">
                      <!-- Display Selected Image in Full Size -->
                      <img :src="`${BASE_URL}${imagesList[currentImageIndex]}`" alt="Selected Image" class="img-fluid">
                    </div>
                    <div class="modal-footer">
                      <div class="dropdown">
                        <button class="btn btn-generate dropdown-toggle" type="button" id="customizeDropdown"
                          data-bs-toggle="dropdown" aria-expanded="false" :disabled="!isSelected">
                          Customize Design
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="customizeDropdown">
                          <li>
                            <button class="dropdown-item" :disabled="!isSelected" @click="openEditor">
                              <img src="/assets/icons/photo-editing.svg" alt="icon" class="icon-class" /> Editor
                            </button>
                          </li>
                          <li>
                            <button class="dropdown-item" @click="openProEditor" :disabled="!isSelected">
                              <img src="/assets/icons/illustration.svg" alt="icon" class="icon-class" /> Pro Editor
                            </button>
                          </li>
                        </ul>
                      </div>
                      <button type="button" class="btn btn-success" @click.prevent="checkDownload">Download</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end:designs-->
          <!--begin:Modals-->
          <div class="row">
            <div class="col">
              <!--begin:library-->
              <div class="row">
                <div class="col">
                  <div class="modal fade" id="libraryModal" tabindex="-1" aria-labelledby="libraryModalLabel"
                    aria-hidden="true">
                    <div
                      class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down modal-dialog-scrollable ps-4 pe-4 library-width">
                      <div class="modal-width modal-content">
                        <div class="modal-header p-3">
                          <h5 class="modal-title" id="libraryModalLabel">Library</h5>
                          <button type="button" class="btn-close" id="closelibra" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                        </div>
                        <div v-if="uploadError" class="alert alert-danger mx-4 br-radius text-center p-1">{{
                          message
                          }}
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                              <select class="form-select font-14" v-model="brand">
                                <option value="0">Select Brand Name</option>
                                <option v-for="(val, index) in row.brands" :key="index" :value="val.id">{{ val.name }}
                                </option>
                              </select>
                            </div>
                            <div class="col-12 col-sm-6 text-center">
                              <loader class="w-50" v-if="uploadloader" />
                              <button v-else type="button" class="btn bg-button px-4" @click="uploadImage()">Upload
                              </button>
                            </div>
                          </div>
                          <div class="row pt-1">
                            <div class="col-12">
                              <div style="position: relative;">
                                <!-- Centered Text and Icon above DropZone -->
                                <div class="d-flex justify-content-center align-items-center pb-2">
                                  <div class="form-text d-flex align-items-center  text-center">
                                    <span><i class="fa-solid fa-cloud-arrow-up font-26 pe-2"></i> Drag a image here or
                                      click to choose a file.</span>
                                  </div>
                                </div>
                                <DropZone @addedFile="onFileAdd" message="Upload your files here" :maxFiles="Number(1)"
                                  :maxFileSize="10000000" url="http://localhost" :uploadOnDrop="false"
                                  :multipleUpload="true" :parallelUpload="3" />
                              </div>
                            </div>
                          </div>
                          <div class="row p-2 font-14 add-image align-items-center">
                            <div class="col-12 col-sm-6 text-center text-sm-start mb-2 mb-sm-0">
                              <span>RECENT UPLOADS</span>
                              <div class="line col-12 col-sm-6 text-center text-sm-start mb-sm-0 mt-0 mb-0 m-1"></div>
                            </div>
                            <div class="col-12 col-sm-6 text-center text-sm-end">
                              <div class="dropdown">
                                <button class="btn btn btn-filter dropdown-toggle" type="button"
                                  id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                  Filter
                                </button>
                                <ul class="dropdown-menu p-2" aria-labelledby="dropdownMenuButton1">
                                  <li>
                                    <label for="">Brand Name</label>
                                    <select name="" v-model="brandFilter" class="form-select" id="">
                                      <option value="">Not selected</option>
                                      <option v-for="(brand, index) in row.brands" :key="index" :value="brand.id">{{
                                        brand.name
                                        }}
                                      </option>
                                    </select>
                                  </li>
                                  <li>
                                    <label for="">Date</label>
                                    <select name="" class="form-select" v-model="date" id="">
                                      <option value="">Not selected</option>
                                      <option value="create_at">Ascending</option>
                                      <option value="-create_at">Descending</option>
                                    </select>
                                  </li>
                                  <li class="py-3 text-center">
                                    <button type="button" @click.prevent="filterGallery()"
                                      class="btn btn-primary px-4">Filter
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <!-- sawaf -->
                          <div class="row" v-if="loadLibrary">
                            <div class="col text-center">
                              <loadersoda />
                            </div>
                          </div>
                          <div class="row pt-3 library-h overflow-auto" style="max-height: 400px;" ref="scrollContainer"
                            @scroll="handleScroll">
                            <div class="col-6 col-sm-4 col-md-3 p-2" v-for="(val, index) in images" :key="index">
                              <label class="mouse checkImage" style="display: block;">
                                <!-- Ensure the checkbox and image are displayed together properly -->
                                <input @change="getImage(val.image, val.id)" type="checkbox"
                                  :checked="recentImage.includes(val.id)" class="form-check-input radio_moved"
                                  style="position: absolute; z-index: 1;" />


                                <!-- Image container with a fixed aspect ratio -->
                                <div class="image-container">
                                  <img class="img-fluid" :src="BASE_URL + val.image" alt="">
                                </div>
                              </label>
                            </div>
                            <!-- Load More Indicator -->
                            <div v-if="loadMore" class="text-center py-4">
                              <loadersoda />
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <span class="add-image"></span>
                          <button class="btn bg-button px-4 btn-add font-color button-okay" @click="closeLibrary()">Okay
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end:library-->
              <!--begin:generateAI-->
              <div class="row">
                <div class="col">
                  <div class="modal fade" id="imageAI" tabindex="-1" aria-labelledby="imageAILabel" aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="imageAILabel">Generate Image AI</h5>
                          <button type="button" class="btn-close" id="closeGenerate" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                        </div>
                        <div v-if="generateAI_Error" class="alert alert-danger mx-4 br-radius text-center p-1">
                          {{ message }}
                        </div>
                        <div class="modal-body">
                          <div class="row py-3">
                            <div class="col text-center">
                              <select class="form-select font-14" v-model="brand">
                                <option value="0">Select Brand Name</option>
                                <option v-for="(val, index) in row.brands" :key="index" :value="val.id">{{ val.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <label for="">description</label>
                              <textarea v-model="descriptAI" class="form-control" rows="4"></textarea>
                            </div>
                          </div>
                          <div class="row pt-3">
                            <div class="col text-center" v-for="(val, index) in galleryAI" :key="index">
                              <label class="mouse" @click="closeGenerateAI()">
                                <input type="radio" :value="val.id" @change="chagefiled" v-model="id_ImageAI"
                                  class="form-check-input radio_moved">
                                <img class="img-uplaod img-fluid" :src="BASE_URL + val.image" alt="">
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="generate-Ai"></div>
                        <div class="modal-footer">
                          <loader v-if="loaderAI" />
                          <button v-else type="button" class="btn bg-button-h" @click.prevent="generateAI">
                            GENERATE AI
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end:generateAI-->
              <!-- Modal begin:editor_Pro:SAWAF -->
              <!-- Modal begin:editor_Pro -->
              <div class="row">
                <div class="col">
                  <div class="modal fade" id="editorProModal" tabindex="-1" aria-labelledby="editorProModalLabel"
                    aria-hidden="true" ref="editorProModal">
                    <div class="modal-dialog modal-xl">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="editorProModalLabel">Pro Editor</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <iframe id="editorProIframe" ref="editorProIframe" src="" frameborder="0"></iframe>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end editor Pro:SAWAF-->

              <!--modal:beginCapion-->
              <div class="row">
                <div class="col">
                  <div class="modal fade" id="caption" tabindex="-1" aria-labelledby="captionLabel" aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="captionLabel">Caption</h5>
                          <button type="button" class="bg-none btn-close" id="closeGenerate" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                        </div>
                        <div v-if="uploadError" class="alert alert-danger text-center">{{ message }}</div>
                        <div class="modal-body pt-0">
                          <div class="row">
                            <div class="col">
                              <textarea v-model="caption" class="form-control" rows="4"></textarea>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn backgroundButton" @click="copyCaption">Copy to
                            Clipboard
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end modal:Caption-->
            </div>
          </div>
          <!--end:Modals-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { saveAs } from 'file-saver';
import { defineComponent } from 'vue';
import { DropZone } from 'dropzone-vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default defineComponent({
  name: 'updesign',
  components: {
    DropZone,
  },
  data() {
    return {
      post_id: this.$route.params.id,
      loading: true,
      cdesignErorr: false,
      message: "",
      row: {},
      brand: 0,
      types: "",
      tones: 0,
      language: 0,
      topic: "",
      hock: "",
      head: "",
      caption: "",
      action: "",
      brandColors: "",
      brandFonts: "",
      brandLogo: "",
      website: "",
      adesign: "",
      pcolor: "",
      scolor: "",
      images: [],
      uploadloader: false,
      uploadError: false,
      recentImage: [],
      generateAI_Error: false,
      loaderAI: false,
      galleryAI: [],
      descriptAI: "",
      id_ImageAI: 0,
      values: {},
      templateV: [],
      template: {},
      rows: [],
      temRetrieveError: false,
      tempLoading: true,
      isLoading: false,
      imagesView: [],
      finalImage: "",
      selected_index: 0,
      load: false,
      loadingText: false,
      brandFilter: 0,
      date: "",
      timestamp: Date.now(),
      generatetext: false,
      variantLoading: false,
      loading: true,
      still_working: false,
      imgLoading: false,
      dataError: false,
      includePrice: 0,
      currency: 0,
      includeSale: 0,
      includeOffer: "",
      priceBefore: 0,
      priceAfter: 0,
      buttonPrice: false,
      buttonSale: false,
      buttonOffer: false,
      buttonBefore_After: false,
      imagesList: [],
      postImage: 0,
      isSelected: false,
      currentImageIndex: null,
      scrollMore: '',
      loadMore: false,
      items: [],
      loadLibrary: false,
      initialData: {},
      isActive: false,
      V$: useVuelidate(),

      //Soda Cooking
      messages: [
        "Hang tight! We're adding the final touches to your Soda masterpiece...",
        "Just a few brush strokes left. Your Soda design is almost here!",
        "We're perfecting every detail. Your creation is worth the wait...",
        "Almost ready! We're excited to see your Soda creation...",
        "Here it comes... Your Soda design is about to be served",
        "We are polishing up the final details for you",
        "Creativity takes time, but we're almost there",
        "Just a bit more, your Soda creation is worth the wait",
        "Finishing touches are being applied",
        "Hold tight, your creation is coming soon!",
        "The wait is nearly over, your Soda design is on its way",
        "We are finalizing everything for a perfect result",
        "Your creative design is almost ready for you.",
        "Just a moment more, your Soda design is nearly complete!",
        "Here it comes… your Soda masterpiece is about to be revealed"
      ],
      currentMessage: "",
      messageIndex: 0,
      typingIndex: 0,
      typingSpeed: 30, // Speed of typing animation in milliseconds
      pauseBetweenMessages: 1900, // Pause time between messages in milliseconds

    }
  },
  validations() {
    return {
      topic: { required },
    }
  },
  computed: {
    ...mapGetters(["BASE_URL", "GET_ROUTE"]),
  },
  methods: {
    openPreviewModal() {
      const previewModal = new bootstrap.Modal(document.getElementById('previewModal'));
      previewModal.show();
    },

    getData() {
      this.dataError = false;
      this.$http.get("/api/templates/init/" + this.post_id + "?update=1").then(res => {
        this.row = res.data;
        this.setInitialData();  // Set initial data after loading the form
        this.loading = false;
      }).catch(err => {
        if (err.data.error) {
          this.message = err.data.error;
        }
        if (err.data.errors) {
          let key = Object.keys(err.data.errors)[0];
          this.message = err.data.errors[key][0];
        }
        this.dataError = true;
        this.loading = false;
        this.loadLibrary = false;
        window.scroll(0, 0);
      })
    },

    templateRetrieve() {
      this.$http.get('/api/templates/get/' + this.post_id + '/').then(res => {
        if (res.data.data.is_draft) {
          this.still_working = true;
          setTimeout(() => {
            this.templateRetrieve();
          }, 5000);
        } else {
          this.still_working = false;
          this.tempLoading = false;
          this.values = res.data.data;
          this.items = res.data.resize;
          this.templateV = this.values.designs;
          this.selected_index = this.values.selected_index;
          this.rows = this.values.variants;
          this.brand = this.values.brand;
          this.types = this.values.sub_design;
          this.tones = this.values.tone;
          this.language = this.values.output_language_ref;
          this.topic = this.values.topic;
          this.hock = this.values.title;
          this.head = this.values.subtitle;
          this.caption = this.values.caption;
          this.action = this.values.cta_text;
          this.brandColors = this.values.brandColors;
          this.brandFonts = this.values.brandFonts;
          this.brandLogo = this.values.brandLogo;
          this.website = this.values.website;
          this.adesign = this.values.adesign;
          this.pcolor = this.values.primary_color;
          this.scolor = this.values.secondary_color;
          this.finalImage = this.BASE_URL + this.templateV[this.selected_index].template.image;
          this.includePrice = this.values.include_price;
          this.currency = this.values.price_currency;
          this.includeSale = this.values.include_sale;
          this.includeOffer = this.values.include_offer;
          this.priceAfter = this.values.price_after;
          this.priceBefore = this.values.price_before;
          this.buttonPrice = this.values.has_price;
          this.buttonSale = this.values.has_sale;
          this.buttonOffer = this.values.has_offer
          this.buttonBefore_After = this.values.has_price_before_after;
          // Initialize imagebox with the loaded images
          // Initialize imagesView and recentImage with images from the response
          if (this.values.images && this.values.images.length > 0) {
            this.imagesView = this.values.images.map(image => image.image);
            this.recentImage = this.values.images.map(image => image.id);
          } else {
            this.imagesView = [];
            this.recentImage = [];
          }
          this.setInitialData();

          this.templateV.map(x => {
            this.imagesList.push(x.template.image);
            x.variants.map(n => {
              this.imagesList.push(n.image);
            })
          })
          window.scroll(0, 0);
        }
      }).catch(err => {
        // console.log(err);
        if (err.response && err.data) {
          if (err.data.error) {
            this.message = err.data.error;
            this.temRetrieveError = true;
          } else if (err.data.errors) {
            let key = Object.keys(err.data.errors)[0];
            this.message = err.data.errors[key][0];
            this.temRetrieveError = true;
          } else {
            this.message = 'An unexpected error occurred';
            this.temRetrieveError = true;
          }
        } else {
          // Handle network errors or cases where no valid response is received
          this.message = 'Network error or server is not responding';
          this.temRetrieveError = true;
        }
        this.tempLoading = true;
        window.scroll(0, 0);
      })
    },

    submitForm() {
      swal({
        title: "Are you sure?",
        text: "when you make a regenerate you will miss the current designs and we will suggest a new designs for you",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes",
        cancelButtonText: "No, cancel ",
        closeOnConfirm: true,
        // closeOnCancel: false
      }, (confirm) => {
        if (confirm) {
          this.V$.$validate();
          this.isLoading = true;
          this.cdesignErorr = false;
          this.$http.post('/api/templates/suggest/?regenerate=' + this.post_id, {
            brand: this.brand,
            temp_type: this.types,
            tone: this.tones,
            post_type: "welcome",
            post_size: this.post_id,
            output_language: this.language,
            topic: this.topic,
            brandColors: this.brandColors,
            brandFonts: this.brandFonts,
            brandLogo: this.brandLogo,
            website: this.website,
            adesign: this.adesign,
            pcolor: this.pcolor,
            scolor: this.scolor,
            image_id: this.recentImage,
            brand_id: this.brand,
            description: this.descriptAI,
            id_ImageAI: this.id_ImageAI,
            price: this.buttonPrice ? this.includePrice : '',
            currency: (this.buttonPrice || this.buttonBefore_After) ? this.currency : '',
            include_sale: this.buttonSale ? this.includeSale : '',
            include_offer: this.buttonOffer ? this.includeOffer : '',
            price_after: this.buttonBefore_After ? this.priceAfter : '',
            price_before: this.buttonBefore_After ? this.priceBefore : '',
          }).then(res => {
            this.isLoading = false;
            window.location.href = "/updesign/" + res.data.data.id;
            // this.$router.push({ name:'updesign' ,params:{id:res.data.data.id}});
          }).catch(err => {
            let error = err.data;

            if (!error) {
              error = err.response;
            }

            if (error) {
              if (error.error) {
                this.message = error.error;
              }
              if (error.errors) {
                let key = Object.keys(error.errors)[0];
                this.message = error.errors[key][0];
              }
            } else {
              this.message = err.message;
            }
            this.cdesignErorr = true;
            this.isLoading = false;
            window.scroll(0, 0);
            // window.scroll(0, document.body.scrollHeight);
          })
        }
      });
    },


    arraysAreEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) return false;
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
      }
      return true;
    },
    compareWithInitialState() {
      const currentState = {
        brand: this.brand,
        temp_type: this.types,
        tone: this.tones,
        post_size: this.post_id,
        output_language: this.language,
        topic: this.topic,
        brandColors: this.brandColors,
        brandFonts: this.brandFonts,
        brandLogo: this.brandLogo,
        website: this.website,
        adesign: this.adesign,
        pcolor: this.pcolor,
        scolor: this.scolor,
        image_id: this.recentImage,
        brand_id: this.brand,
        description: this.descriptAI,
        id_ImageAI: this.id_ImageAI,
        price: this.buttonPrice ? this.includePrice : '',
        currency: (this.buttonPrice || this.buttonBefore_After) ? this.currency : '',
        include_sale: this.buttonSale ? this.includeSale : '',
        include_offer: this.buttonOffer ? this.includeOffer : '',
        price_after: this.buttonBefore_After ? this.priceAfter : '',
        price_before: this.buttonBefore_After ? this.priceBefore : '',
        imagesView: [...this.imagesView],


      };
      // Check if the current form data is different from the initial state
      this.isActive = JSON.stringify(currentState) !== JSON.stringify(this.initialData);
    },

    setInitialData() {
      this.initialData = {
        brand: this.brand,
        temp_type: this.types,
        tone: this.tones,
        post_size: this.post_id,
        output_language: this.language,
        topic: this.topic,
        brandColors: this.brandColors,
        brandFonts: this.brandFonts,
        brandLogo: this.brandLogo,
        website: this.website,
        adesign: this.adesign,
        pcolor: this.pcolor,
        scolor: this.scolor,
        image_id: this.recentImage,
        brand_id: this.brand,
        description: this.descriptAI,
        id_ImageAI: this.id_ImageAI,
        price: this.buttonPrice ? this.includePrice : '',
        currency: (this.buttonPrice || this.buttonBefore_After) ? this.currency : '',
        include_sale: this.buttonSale ? this.includeSale : '',
        include_offer: this.buttonOffer ? this.includeOffer : '',
        price_after: this.buttonBefore_After ? this.priceAfter : '',
        price_before: this.buttonBefore_After ? this.priceBefore : '',
        imagesView: [...this.imagesView],
      };
    },

    uploadImage() {
      const formData = new FormData()
      formData.append("image", this.image);
      formData.append("brand", this.brand);
      if (!this.brand) {
        this.message = "You must select brand";
        this.uploadError = true;
        return false;
      }
      if (!this.image) {
        this.message = "You must choose the image";
        this.uploadError = true;
        return false;
      }
      this.uploadloader = true;
      this.uploadError = false;
      this.$http.post("/api/templates/images/upload/", formData).then(res => {

        // console.log(res);
        this.image = "";
        this.uploadloader = false;
        document.getElementsByClassName('gg-close')[0].click()
        this.getImagelist();
      }).catch(err => {
        // console.log(err);
        if (err.data.error) {
          this.message = err.data.error;
          this.cdesignErorr = true;
        }
        if (err.data.errors) {
          let key = Object.keys(err.data.errors)[0];
          this.message = err.data.errors[key][0];
        }
        this.uploadError = true;
        this.uploadloader = false;
        window.scroll(0, 0);
      })
    },

    closeLibrary() {
      document.getElementById('closelibra').click();
    },

    // Fetch initial set of images (8 images)
    getImagelist() {
      this.loadingLibrary = true;
      this.autoLoadAttempts = 0; // Reset auto-load attempts
      this.$http
        .get("/api/templates/images/", { params: { limit: 8 } })
        .then((res) => {
          this.images = res.data.results;
          this.nextPage = res.data.next; // Capture the next page URL
          this.loadingLibrary = false;
          this.$nextTick(() => {
            this.checkIfMoreImagesNeeded();
          });
        })
        .catch((err) => {
          if (err.data.error) {
            this.message = err.data.error;
          }
          if (err.data.errors) {
            let key = Object.keys(err.data.errors)[0];
            this.message = err.data.errors[key][0];
          }
          this.loadingLibrary = false;
        });
    },

    // Fetch more images when scrolling
    loadMoreImages() {
      if (this.nextPage) {
        this.loadMore = true;
        this.$http
          .get(this.nextPage)
          .then((res) => {
            this.images.push(...res.data.results); // Append new images
            this.nextPage = res.data.next; // Update the next page URL
            this.loadMore = false;
            this.$nextTick(() => {
              this.checkIfMoreImagesNeeded();
            });
          })
          .catch((err) => {
            if (err.data.error) {
              this.message = err.data.error;
            }
            if (err.data.errors) {
              let key = Object.keys(err.data.errors)[0];
              this.message = err.data.errors[key][0];
            }
            this.loadMore = false;
          });
      }
    },
    checkIfMoreImagesNeeded() {
      const scrollContainer = this.$refs.scrollContainer;
      // Check if scrollContainer exists
      if (!scrollContainer) {
        // console.warn('scrollContainer is not available.');
        return; // Exit the method early since there's nothing to do
      }
      // Check if container is scrollable
      if (
        scrollContainer.scrollHeight <= scrollContainer.clientHeight &&
        this.nextPage &&
        this.autoLoadAttempts < 5 // Limit to prevent infinite loading
      ) {
        this.autoLoadAttempts++;
        this.loadMoreImages();
      }
    },
    // Handle scroll event on the library-h div
    handleScroll() {
      const scrollContainer = this.$refs.scrollContainer;
      const scrollPosition = scrollContainer.scrollTop + scrollContainer.clientHeight;
      const bottomPosition = scrollContainer.scrollHeight;
      if (scrollPosition >= bottomPosition - 10 && this.nextPage) {
        this.loadMoreImages();
      }
    },
    sortImages() {
      if (this.date === 'create_at') {
        this.images.sort((a, b) => new Date(a.create_at) - new Date(b.create_at));
      } else if (this.date === '-create_at') {
        this.images.sort((a, b) => new Date(b.create_at) - new Date(a.create_at));
      }
    },
    filterGallery() {
      this.filterLoading = true;
      this.autoLoadAttempts = 0; // Reset auto-load attempts
      let params = {};
      params.limit = 8;
      if (this.brandFilter) {
        params.brand = this.brandFilter;
      }
      this.$http
        .get("/api/templates/images/", { params })
        .then((res) => {
          this.images = res.data.results;
          this.nextPage = res.data.next;
          this.filterLoading = false;
          this.sortImages(); // Apply Frontend-side sorting
          this.$nextTick(() => {
            this.checkIfMoreImagesNeeded();
          });
        })
        .catch(err => {
          if (err.data.error) {
            this.message = err.data.error;
          }
          if (err.data.errors) {
            let key = Object.keys(err.data.errors)[0];
            this.message = err.data.errors[key][0];
          }
          this.hasError = true;
          this.filterLoading = false;
          window.scroll(0, 0);
        });
    },
    getNext() {
      this.nextPage += 1;
      console.log(this.nextPage);
    },
    getPrev() {
      this.prevPage -= 1;
      console.log(this.prevPage);
    },
    uploadFile(files) {
      this.image = files[0];
    },
    generateAI() {
      if (!this.brand) {
        this.message = "You must choose the brand name";
        this.generateAI_Error = true;
      }
      if (!this.descriptAI) {
        this.message = "You must write description";
        this.generateAI_Error = true;
      }
      this.loaderAI = true;
      this.generateAI_Error = false;
      this.$http.post("/api/templates/images/generate/", {
        brand_id: this.brand,
        description: this.descriptAI,
      }).then(res => {
        // console.log(res);
        this.galleryAI = res.data.images;
        this.loaderAI = false;
      }).catch(err => {
        // console.log(err);
        if (err.data.error) {
          this.message = err.data.error;
        }
        if (err.data.errors) {
          let key = Object.keys(err.data.errors)[0];
          this.message = err.data.errors[key][0];
        }
        this.generateAI_Error = true;
        this.loaderAI = false;
        window.scroll(0, 0);
      })
    },
    closeGenerateAI() {
      document.getElementById('closeGenerate').click();
    },
    getImage(imageUrl, imageId) {
      // Check if the image is already in recentImage
      const imageIndex = this.recentImage.indexOf(imageId);
      const imageUrlIndex = this.imagesView.indexOf(imageUrl);
      // Prevent adding more than 3 images
      if (this.imagesView.length >= 3 && imageIndex === -1) {
        swal("Limit Reached", "You can only select up to 3 images.", "warning");
        return;
      }

      if (imageIndex === -1) {
        // Image is not selected, so select it
        this.recentImage.push(imageId);
        this.imagesView.push(imageUrl);
      } else {
        // Image is already selected, so deselect it
        this.recentImage.splice(imageIndex, 1);
        if (imageUrlIndex !== -1) {
          this.imagesView.splice(imageUrlIndex, 1);
        }
      }
      // Re-check the form state
      this.compareWithInitialState();
    },
    changeImage(index) {
      const imageUrl = this.imagesView[index];
      const imageIdIndex = this.recentImage.findIndex(id => id === this.getImageIdByUrl(imageUrl));

      // Remove the image from both arrays and ensure sync with recentImage
      if (imageIdIndex !== -1) {
        this.recentImage.splice(imageIdIndex, 1);
      }
      this.imagesView.splice(index, 1);

      // Open the library popup
      document.getElementById('openLibrary').click();

      // Re-check the form state
      this.compareWithInitialState();
    },
    deleteImage(index) {
      swal({
        title: "Are you sure?",
        text: "You will not be able to recover this image",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
        closeOnConfirm: true,
      }, () => {
        this.delImage(index);
      });
    },

    // Helper function to actually delete the image
    delImage(index) {
      const imageUrl = this.imagesView[index];
      const imageIdIndex = this.recentImage.findIndex(id => id === this.getImageIdByUrl(imageUrl));

      // Remove the image from both arrays
      if (imageIdIndex !== -1) {
        this.recentImage.splice(imageIdIndex, 1);
      }
      this.imagesView.splice(index, 1);

      swal("Deleted!", "Your image has been deleted.", "success");

      // Ensure checkboxes in popup are updated and the form state is compared
      this.compareWithInitialState();
    },
    getImageIdByUrl(imageUrl) {
      const foundImage = this.images.find(img => img.image === imageUrl);
      return foundImage ? foundImage.id : null;
    },

    checkDownload() {
      saveAs(this.BASE_URL + this.imagesList[this.currentImageIndex], "image.png");
    },
    nextPage() {
      // this.load = true;
      this.selected_index = this.selected_index + 1;
      let id = this.templateV[this.selected_index].template.id;
      this.$http.get('/api/templates/select/' + this.post_id + '/' + id + '/');
      // this.load = false;
    },
    prevPage() {
      this.selected_index = this.selected_index - 1;
      let id = this.templateV[this.selected_index].template.id;
      this.$http.get('/api/templates/select/' + this.post_id + '/' + id + '/');
    },
    changeVariant(id) {
      this.variantLoading = true;
      this.$http.get('/api/templates/selectVariant/' + this.post_id + '/' + id + '/').then(res => {
        // console.log(res);
        this.variantLoading = false;
        this.values = res.data.data;
        this.templateV = this.values.designs;
        this.selected_index = this.values.selected_index;
        this.rows = this.values.variants;
        this.brand = this.values.brand;
        this.types = this.values.sub_design;
        this.tones = this.values.tone;
        this.language = this.values.output_language_ref;
        this.topic = this.values.topic;
        this.hock = this.values.title;
        this.head = this.values.subtitle;
        this.caption = this.values.caption;
        this.action = this.values.cta_text;
        this.brandColors = this.values.brandColors;
        this.brandFonts = this.values.brandFonts;
        this.brandLogo = this.values.brandLogo;
        this.website = this.values.website;
        this.adesign = this.values.adesign;
        this.pcolor = this.values.primary_color;
        this.scolor = this.values.secondary_color;
        this.finalImage = this.BASE_URL + this.templateV[this.selected_index].template.image;
      }).catch(err => {
        console.log(err);

      })
    },

    updateText() {
      this.loadingText = true;
      this.$http.put('/api/templates/update/' + this.post_id + '/', {
        // title: this.hock,
        // subtitle: this.head,
        caption: this.caption,
        // cta_text: this.action,
      })
        .then(res => {
          this.loadingText = false;
          this.hock = res.data.data.title;
          this.head = res.data.data.subtitle;
          this.caption = res.data.data.caption;
          this.action = res.data.data.cta_text;
          this.timestamp = Date.now();
        }).catch(err => {
          console.log(err);
        })
    },
    onFileAdd(e) {
      this.image = e.file;
      this.uploadImage();
    },
    regenrateText() {
      this.generatetext = true;
      this.$http.get('/api/templates/regenerateText/' + this.post_id + '/').then(res => {
        // console.log(res);
        this.generatetext = false;
        this.hock = res.data.data.title;
        this.head = res.data.data.subtitle;
        this.caption = res.data.data.caption;
        this.action = res.data.data.cta_text;
      }).catch(err => {
        if (err.data.error) {
          this.message = err.data.error;
        }
        if (err.data.errors) {
          let key = Object.keys(err.data.errors)[0];
          this.message = err.data.errors[key][0];
        }
        this.generateAI_Error = true;
        this.loaderAI = false;
        window.scroll(0, 0);
      })
    },
    getSVGurl(url) {
      let urlArray = url.split('/');
      let index = urlArray.indexOf('images');
      if (index != -1) {
        urlArray.splice(index, 1);
        let imageNameArray = urlArray[urlArray.length - 1].split('.');
        imageNameArray[imageNameArray.length - 1] = 'svg';
        urlArray[urlArray.length - 1] = imageNameArray.join('.');
      }
      return urlArray.join('/');
    },
    shareModal() {
      // Show the modal
      if (!this.shareModalInstance) {
        // Initialize the modal instance if it doesn't exist
        this.shareModalInstance = new bootstrap.Modal(this.$refs.shareModalModal);
      }
      this.shareModalInstance.show();
    },
    openProEditor() {
      let svgUrl = this.getSVGurl(this.BASE_URL + this.imagesList[this.currentImageIndex]);
      let sodaicon = "https://app.sodamarketing.ai/assets/images/Vector.png"
      let jsonData = `{
    "files": [
        "${svgUrl}"
    ],
    "environment": {
        "theme": 6,
        "lang": "en",
        "vmode": 1,
        "intro": false,
        "localsave": true,
        "menus": [
            1,
            0,
            [
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                [
                    1,
                    1,
                    0,
                    1,
                    1
                ],
                1
            ],
            0,
            1
        ],
        "plugins": [
            {
                "name": "Soda AI",
                "url": "https://sodamarketing.ai/",
                "icon": "${sodaicon}"
            }
        ]
    }
}`;
      $('#editorProIframe').attr('src', `https://www.photopea.com#${encodeURIComponent(jsonData)}`);
      $('#editorProModal').modal('show');
    },
    openEditor() {
      const modalBackdrop = document.querySelector('.modal-backdrop');
      if (modalBackdrop) {
        modalBackdrop.parentNode.removeChild(modalBackdrop);
      }
      let svgUrl = this.getSVGurl(this.imagesList[this.currentImageIndex]);
      this.$router.push({ name: 'SVGEditor', query: { svg_path: svgUrl } });
    },
    selected_image(index) {
      this.currentImageIndex = index;
      this.isSelected = true;
      // Scroll to the action buttons after the DOM has updated
      this.$nextTick(() => {
        const actionButtons = this.$refs.actionButtons;
        if (actionButtons) {
          actionButtons.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      });

      let currentSelected = document.getElementsByClassName('bg-image-selected');
      for (let i = 0; i < currentSelected.length; i++) {
        currentSelected[i].classList.remove('bg-image-selected');
      }
      let imgElement = document.getElementById('vue-image-' + index).parentElement;
      imgElement.classList.add('bg-image-selected');
    },
    postResize(key) {
      this.still_working = true;
      this.$http.get('/api/templates/resize/' + this.post_id + '/' + key + '/').then(res => {
        // console.log(res);
        this.still_working = false;
        window.location.href = "/updesign/" + res.data.data.id;
      }).catch(err => {
        if (err.data.error) {
          this.message = err.data.error;
        }
      })
    },
    copyCaption() {
      // Copy the caption to the clipboard
      navigator.clipboard.writeText(this.caption).then(() => {
        // Show a success message (optional)
        this.copySuccess = true;

        // Close the modal after a short delay (optional)
        setTimeout(() => {
          this.closeModal('caption');
          this.copySuccess = false;
        }, 1000); // Delay in milliseconds
      }).catch(err => {
        // Handle error (optional)
        console.error('Failed to copy text: ', err);
      });
    },

    closeModal(modalId) {
      const modalElement = document.getElementById(modalId);
      const modalInstance = bootstrap.Modal.getInstance(modalElement);
      if (modalInstance) {
        modalInstance.hide();
      } else {
        // If the modal instance doesn't exist, create one and hide it
        const newModalInstance = new bootstrap.Modal(modalElement);
        newModalInstance.hide();
      }
    },
    chagefiled() {
      // this.isActive = true;
      this.compareWithInitialState();

    },
    startTyping() {
      const typeNextCharacter = () => {
        if (this.typingIndex < this.messages[this.messageIndex].length) {
          // Add the next character to the current message
          this.currentMessage += this.messages[this.messageIndex][this.typingIndex];
          this.typingIndex++;
          // Continue typing the next character after a delay
          setTimeout(typeNextCharacter, this.typingSpeed);
        } else {
          // Pause after finishing the message, then move to the next message
          setTimeout(() => {
            this.typingIndex = 0;
            this.currentMessage = "";
            this.messageIndex = (this.messageIndex + 1) % this.messages.length;
            // Start typing the next message after a pause
            setTimeout(typeNextCharacter, this.typingSpeed);
          }, this.pauseBetweenMessages);
        }
      };

      // Start typing the first message
      typeNextCharacter();
    }
  },
  watch: {
    recentImage: {
      handler(newValue, oldValue) {
        this.compareWithInitialState();
      },
      deep: true
    }
  },

  created() {
    this.getData();
    this.getImagelist();
    this.templateRetrieve();
    this.setInitialData();
  },
  mounted() {
    // Initialize the modal instance
    this.editorProModalInstance = new bootstrap.Modal(this.$refs.editorProModal);

    // Add event listener for modal close to clear the iframe src
    this.$refs.editorProModal.addEventListener('hidden.bs.modal', () => {
      // Clear the iframe src
      this.$refs.editorProIframe.src = '';
    });

    this.getData();
    this.startTyping();
  },
  unmounted() {
    // Remove scroll event listener when component is destroyed
    window.removeEventListener("scroll", this.handleScroll);
  },
});
</script>
<style scoped>
.library-h {
  max-height: 70vh;

  overflow-y: auto;

}

.image-item {
  width: 100%;
  height: 150px;
  /* Set a fixed height for the images */
  object-fit: cover;

}

:root {
  --image-height: 150px;
  /* Match the height set for .image-item */
}

/* Scrollbar Styles */
.scroll-container::-webkit-scrollbar {
  width: 8px;
}

.scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #e45d0c;
  border-radius: 4px;
  border: 2px solid #f1f1f1;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #e45d0c;
}

/* For Firefox */
.scroll-container {
  scrollbar-width: thin;
  scrollbar-color: #e45d0c #f1f1f1;
}

.bg-gray {
  background-color: #fafafa;
  /* Adjust the background color as needed */
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

/* Ensure images are displayed correctly */
.img-fluid {
  width: 100%;
  height: auto;
}

.img-fluid-preview {
  width: 100%;
  height: auto;
  border-radius: 3%;


}

.icon-class {
  width: 16px;

  height: 16px;

  margin-left: 5px;

}

.icon-class-bg {
  width: 30px;

  height: 30px;

  margin-left: 5px;

}

.share-social-icon {
  width: 40px;

  height: 40px;
  cursor: pointer;
  transition: transform 0.2s;
}

.share-social-icon:hover {
  transform: scale(1.1);

}

.library-width {
  min-width: 48% !important;
}

.button-okay {
  background-color: #f70081;
}

.card {
  border-radius: 17px;
  background-color: #ffffff;
}

/* Align the form elements better and ensure spacing is consistent */
.form-check-label {
  margin-left: 0.5rem;
  font-size: 12px;
}

.input-group {
  display: flex;
  align-items: center;
}

.form-check-input {
  transform: scale(1.2);
}

.form-label {
  font-size: 0.9rem;
  font-weight: bold;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .form-label {
    font-size: 0.85rem;
  }
}

.bgcustom {
  background-color: #ffffff;

}

.images-width-new {
  width: 20px;
}

/* For image Box */

.imagebox {
  position: relative;
}

.image-container-box {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 150%;
  border-radius: 8px;
}

.img-fluid-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(214, 214, 214, 0.267);
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container-box:hover .overlay {
  opacity: 1;
}

.action-text {
  background-image: linear-gradient(45deg, #E26600, #F70081, transparent);
  color: white;
  font-size: 10px;
  opacity: 0.8;
  font-weight: bolder;
  cursor: pointer;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 5px;
}

.delete-icon {
  color: #ff0000;
  font-size: 18px;
  margin-top: 15px;
  cursor: pointer;
}

.bgcolor {
  background-color: #fafafa;

}

svg#Layer_1 {
  max-width: -webkit-fill-available;
}

@media screen and (max-width: 600px) {
  svg text {
    font-size: 28px !important;
    transform: translate(-100px, 0px) !important;
  }
}


@keyframes moveUpDown {
  0% {
    transform: translateY(0);
    /* Initial position */
  }

  50% {
    transform: translateY(-20px);
    /* Move up */
  }

  100% {
    transform: translateY(0);
    /* Move back down */
  }
}

svg g {
  animation: moveUpDown 2s ease-in-out infinite;
}
</style>