<template>
  <div class="container-fluid bgcustom pt-3 pb-5 px-3 px-md-4">
    <div class="card shadow rounded-5 hborder p-3 p-md-4">
      <div class="card-body pt-0 mt-3 mt-md-4">
        <div class="title-container text-center">
          <div v-if="errors" class="alert alert-danger text-center p-1 br-radius">
            <span v-for="(error, index) in errors" :key="index" class="text-color">{{ error }}</span>
          </div>
          <h3 class="fs-5 fs-md-4">{{ type }} Subscriptions</h3>
        </div>

        <!-- Scrollable Table -->
        <div class="table-scroll-container">
          <table class="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Start From</th>
                <th>Valid Till</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="sub in filteredSubscriptions" :key="sub.id">
                <td>{{ sub.display_name }}</td>
                <td>{{ sub.current_period_start }}</td>
                <td>{{ sub.current_period_end }}</td>
                <td>
                  <span :class="['badge', sub.status === 'active' ? 'badge-success' : 'badge-warning']">
                    {{ sub.status }}
                  </span>
                </td>
                <td>
                  <button v-if="sub.status === 'active'" class="btn btn-outline-primary btn-sm"
                    @click="openCancelModal(sub.id)">
                    Cancel
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="filteredSubscriptions.length === 0" class="text-center mt-4">
          <p>No subscriptions found for {{ type }}</p>
        </div>
      </div>
    </div>
    <!-- Confirmation Modal -->
    <div v-if="showCancelModal" class="modal fade show d-block" tabindex="-1" style="background: rgba(0, 0, 0, 0.5);">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content text-center p-4">
          <h5 class="text-primary mb-3">Confirm Cancellation</h5>
          <p>Are you sure you want to cancel this subscription?</p>
          <div class="d-flex justify-content-center gap-2">
            <button class="btn btn-secondary" @click="closeCancelModal">No, Keep it</button>
            <button class="btn btn-danger" @click="confirmCancel">Yes, Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { listSubscriptions, cancelSubscription } from "@/apis";
import globalErrorHandler from "@/globalErrorHandler";


export default {
  data() {
    return {
      subscriptions: [],
      type: this.$route.params.type,
      showCancelModal: false, // Track if cancel modal is shown
      subscriptionToCancel: null, // Store the subscription ID to cancel
      errors: null, // To display any error messages
      showSuccessPopup: false,
      showScrollIndicator: true

    };
  },
  computed: {
    filteredSubscriptions() {
      return this.subscriptions.filter((sub) => sub.display_name === this.type);
    }
  },
  methods: {
    scrollHandler() {
      const tableContainer = this.$refs.tableContainer;
      this.showScrollIndicator =
        tableContainer.scrollLeft + tableContainer.clientWidth < tableContainer.scrollWidth;
    },
    async fetchSubscriptions() {
      try {
        const response = await listSubscriptions();
        this.subscriptions = response.results;
      } catch (error) {
        this.errors = globalErrorHandler(error.data);
      }
    },
    openCancelModal(id) {
      this.subscriptionToCancel = id;
      this.showCancelModal = true;
    },
    closeCancelModal() {
      this.showCancelModal = false;
      this.subscriptionToCancel = null;
    },
    async confirmCancel() {
      if (!this.subscriptionToCancel) return;

      try {
        await cancelSubscription(this.subscriptionToCancel, { cancellation_type: "at_period_end" });
        this.showCancelModal = false; // Hide the modal on success
        this.subscriptionToCancel = null; // Clear the stored ID
        this.fetchSubscriptions(); // Refresh the list to reflect changes
        this.showSuccessPopup = true;
        setTimeout(() => {
          this.showSuccessPopup = false;
        }, 2000);
      } catch (error) {
        this.errors = globalErrorHandler(error.data);
      }
    }
  },
  created() {
    this.fetchSubscriptions();
  }
};
</script>

<style scoped>
.container-fluid {
  background-color: #ffffff;
}

.card {
  border-radius: 17px;
  background-color: #ffffff;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.fs-5 {
  font-size: 1.25rem;
}

.fs-md-4 {
  font-size: 1.5rem;
}

.table-responsive {
  overflow-x: auto;
}

.badge-success {
  background-color: #28a745;
  color: white;
}

.badge-warning {
  background-color: #E26600;
  color: white;
}

.btn-outline-primary {
  border-color: #E26600;
  color: #E26600;
  font-size: 0.875rem;
}

.btn-outline-primary:hover {
  background-color: #E26600;
  color: #ffffff;
}

.modal-content {
  background-color: #ffffff;
  border-radius: 10px;
}

.modal.fade.show.d-block {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.text-warning-s {
  color: #E26600;
}

.table-scroll-container {
  overflow-x: auto;
  max-width: 100%;
}

/* Optional: Style tweaks for smoother scrolling experience */
.table-scroll-container::-webkit-scrollbar {
  height: 8px;
}

.table-scroll-container::-webkit-scrollbar-thumb {
  background-color: #f21764;
  border-radius: 4px;
}

.table-scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}
</style>
