import axios from "axios";


const BASE_URL = "https://backend.sodamarketing.ai";

const TOKEN = localStorage.getItem("token");

axios.defaults.baseURL = BASE_URL;
if (TOKEN) {
  axios.defaults.headers.common["Authorization"] = `Token ${TOKEN}`;
}

export function subscribe({ email, password, ...data }) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/subscriptions/register/`, {
        email: email, password: password,
        ...data,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function resendVerificationEmail({ email }) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/subscriptions/sendVerifyEmail/`, {
        email: email
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function retrieveUserData() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/auth/user/`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function verifyEmail({ email, code }) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/subscriptions/verifyEmail/`, {
        email: email,
        code: code
      })

      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("role", response.data.user.access_role);
        axios.defaults.headers.common["Authorization"] = `Token ${response.data.token}`;
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function loginSession({ email, password }) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/auth/login/`, {
        email: email,
        password: password
      })
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("role", response.data.user.access_role);
        axios.defaults.headers.common["Authorization"] = `Token ${response.data.token}`;
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function fetchPlans() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/subscriptions/product?type=plan`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function fetchAddons() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/subscriptions/product?type=addon`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function retrieveProductByID(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/subscriptions/product/id/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function retrieveProductByName(name) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/subscriptions/product/name/${name}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function listSubscriptions() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/subscriptions/subscription`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function retrieveBaseSubscriptions() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/subscriptions/subscription/default`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function retrieveSubscriptionByID(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/subscriptions/subscription/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function listPaymentMethods() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/billing/paymentmethod`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function setupIntent() {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/billing/setupintent/`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function addPaymentMethod({ payment_method_id, is_default = true }) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/billing/paymentmethod/add/`, {
        payment_method_id: payment_method_id, is_default: is_default
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function setDefaultPaymentMethod(id, { is_default = true }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/billing/paymentmethod/${id}/update/`, {
        is_default: is_default
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function removePaymentMethod(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/api/billing/paymentmethod/${id}/remove/`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function upgradeSubscription(id, { pricing }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/subscriptions/subscription/${id}/upgrade/`, {
        pricing: pricing
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function downgradeSubscription(id, { pricing }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/subscriptions/subscription/${id}/downgrade/`, {
        pricing: pricing
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function cancelSubscription(id, { cancellation_type = 'at_period_end' }) {
  // immediately or at_period_end
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/subscriptions/subscription/${id}/cancel/`, {
        cancellation_type: cancellation_type
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function attachAddon({ pricing, quantity = 1 }) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/subscriptions/subscription/attach/`, {
        pricing: pricing,
        quantity: quantity,

      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function listInvoices(page = 1) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/billing/invoice`, {
        params: { page }
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function retrieveInvoiceByID(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/billing/invoice/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function resubscribeSubscription(id, { pricing }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/subscriptions/subscription/resubscribe/${id}/`, { pricing: pricing })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function retrieveSvgContent(path) {
  return new Promise((resolve, reject) => {
    axios
      .get(`https://backend.sodamarketing.ai${path}`, {
        responseType: "text",
        transformRequest: [(data, headers) => {
          delete headers['Authorization'];
          headers['Content-Type'] = 'image/svg+xml';
          headers['Accept'] = 'image/svg+xml';
          return data;
        }]
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}