import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import LoaderSoda from './components/LoaderSoda.vue';
import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
import MinLoader from './components/MinLoader.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import DropZone from 'dropzone-vue';
import 'dropzone-vue/dist/dropzone-vue.common.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import VueLazyload from 'vue-lazyload';


axios.defaults.baseURL = 'https://backend.sodamarketing.ai';

axios.interceptors.response.use((response) => {
  return response;
}, function (error) {
  if (error.response.status == 401 && router.currentRoute.value.path !== '/login') {

    window.location.href = "/";
  }
  return Promise.reject(error.response);
});

(async () => {
  await store.dispatch('checkUser');
  createApp(App)
    .use(VueAxios, axios)
    .use(store)
    .use(router)
    .use(DropZone)
    .use(Vue3ColorPicker)
    .use(VueLazyload, {
      preLoad: 1.3,
      error: '/assets/images/soda.png',
      loading: '/assets/images/loding.gif',
      attempt: 1,
    })
    .component('loader', MinLoader)
    .component('loadersoda', LoaderSoda)
    .mount('#app');
})();






