<template>
  <!-- Header Section -->
  <div class="container-fluid bgcustom pt-3 pb-5 mt-2">
    <div class="card shadow rounded-5 hborder mx-auto" style="max-width: 1400px;">
      <div class="card-body text-center">
        <img src="/assets/images/logo-soda.svg" class="logo-width" alt="">
      </div>
      <div class="card-body text-center">
        <div v-if="subscribeError" class="alert alert-danger container w-50"> {{ message }} </div>
      </div>
      <div class="card-body text-center">
        <!-- Body -->
        <h5 class="fw-lighter mb-3 h5 colorfontdark">Hi {{ firstName }} {{ lastName }},</h5>
        <h2 class="fw-bold mb-3 text-danger">Your Free Trial Has Ended</h2>

        <!-- Clear Trial Expiration Message -->
        <h4 class="lead-padding">
          To continue using SodaMarketing.AI and unlocking unlimited graphic designs & social media content, please
          upgrade to a paid plan.
        </h4>
        <hr class="divider mx-auto" />
        <!-- Plans Section -->
        <div class="plans-section py-3">
          <div class="container">
            <h2 class="fw-bold text-center mb-5">Choose the Best Plan for Your Business</h2>
            <div v-if="errors" class="alert alert-danger mx-4 text-center p-1 br-radius">
              <span v-for="(error, index) in errors" :key="index" class="text-color">{{ error }}</span>
            </div>

            <div v-if="access_Role === 'owner'">
              <div class="d-flex align-items-center justify-content-center my-3 ms-5">
                <!-- Button Group for Monthly/Yearly -->
                <div class="btn-group rounded-pill" role="group" aria-label="Billing Cycle">
                  <button type="button" class="btn btn-outline-primary rounded-pill1"
                    :class="{ active: billingCycle === 'monthly' }" @click="billingCycle = 'monthly'">
                    Monthly
                  </button>
                  <button type="button" class="btn btn-outline-primary rounded-pill2"
                    :class="{ active: billingCycle === 'yearly' }" @click="billingCycle = 'yearly'">
                    Yearly
                  </button>
                </div>
                <img src="/assets/images/offersave.svg" alt="Save 10%" class="save-image ms-0" />
              </div>
              <div class="row justify-content-center ">
                <div class="col-md-4 mb-4" v-for="(plan, index) in rows" :key="index">
                  <div
                    :class="['plan-card', { 'selected': isActive(getPricingId(plan.pricing)), 'hovered': hoveredIndex === index }]"
                    @click="selectPlan(plan)" @mouseover="hoveredIndex = index" @mouseleave="hoveredIndex = null">
                    <div class="card-body d-flex flex-column align-items-baseline">
                      <h3 class="card-title fw-bold">{{ plan.name }}</h3>
                      <p class="text-start fw-bold">
                        <span v-if="billingCycle === 'yearly'">
                          <h5 class="text-decoration-line-through text-muted">{{ getOriginalMonthlyPrice(plan) }}
                          </h5>
                          <h3 class="mb-1">{{ getDiscountedMonthlyPrice(plan) }}</h3>
                          <small class="text-muted">Billed Annually</small> <!-- Billed Annually Text -->
                        </span>
                        <span v-else>
                          <h3 class="mb-1">{{ getMonthlyPrice(plan) }}</h3> <!-- Monthly Price -->
                        </span>
                      </p>
                      <!-- Plan Features -->
                      <ul class="list-unstyled text-start my-4 plan-features lh-lg">
                        <li v-for="(feature, featIndex) in plan.features" :key="featIndex">
                          <i class="fa-solid fa-check iconcolor"></i>{{ feature.name }}
                        </li>
                      </ul>

                      <button class="btn btn-primary mt-auto w-100 choose-plan-btn" @click="selectPlan(plan)">
                        Choose {{ plan.name }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Non-owner message -->
            <div v-else>
              <p class="text-center alert alert-warning">Please contact the account owner for an upgrade.</p>
            </div>
          </div>
        </div>
        <!-- Testimonials or Social Proof -->
        <div class="why-trust-section py-5">
          <div class="container text-center">
            <h2 class="fw-bold mb-4">Why Businesses Trust SodaMarketing.AI</h2>
            <p class="lead mb-5">Thousands of businesses are using SodaMarketing.AI to boost their brand presence with
              high-quality designs and content that resonate with their audience. Here’s what some of our happy
              customers have to say:</p>

            <!-- Testimonial Section -->
            <div class="row justify-content-center">
              <div class="col-md-4 mb-4" v-for="testimonial in testimonials" :key="testimonial.name">
                <div class="card shadow testimonial-card h-100">
                  <div class="card-body">
                    <div class="testimonial-icon mb-3">
                      <i class="fas fa-quote-left fa-2x"></i>
                    </div>
                    <p class="testimonial-text">{{ testimonial.quote }}</p>
                    <footer class="blockquote-footer">
                      <strong>{{ testimonial.name }}</strong>, {{ testimonial.position }} at {{ testimonial.company }}
                    </footer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- FAQ or Contact Section -->
        <div class="mt-4">
          <p>
            <a class="mouse" @click="methodlogOut()">
              <i class="fa-solid fa-right-from-bracket p-color"></i>
              Log Out
            </a>
          </p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  upgradeSubscription,
  downgradeSubscription,
  fetchPlans,
  retrieveBaseSubscriptions,
} from "@/apis";
import globalErrorHandler from "@/globalErrorHandler";



export default {
  data() {
    return {
      rows: [],
      errors: null,
      plan_id: "",
      billingCycle: "yearly",
      hoveredIndex: null,
      planError: false,
      message: "",
      objPlan: {},
      subscribeError: false,
      access_Role: localStorage.getItem("role"),
      testimonials: [
        {
          name: "Sarah Johnson",
          position: "Marketing Director",
          company: "BrightFuture Co.",
          quote: "SodaMarketing.AI helped us increase our social media engagement by 200%! It's like having a full design team on-demand."
        },
        {
          name: "David Lee",
          position: "Founder",
          company: "StartRight Inc.",
          quote: "We saved over $5,000 in design costs in just three months with SodaMarketing.AI. The AI-powered designs are stunning and customizable."
        },
        {
          name: "Emily Chen",
          position: "Brand Manager",
          company: "EcoGreen Corp.",
          quote: "Thanks to SodaMarketing.AI, our brand’s online presence looks more professional than ever. The platform is a game changer!"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['GET_DATA_USER']),
    firstName() {
      return this.GET_DATA_USER.firstName;
    },
    lastName() {
      return this.GET_DATA_USER.lastName;
    }
  },
  methods: {
    // Fetch available plans and current user's plan
    // Fetch available plans and current user's plan
    async planProduct() {
      try {
        const [plansRes, subRes] = await Promise.all([fetchPlans(), retrieveBaseSubscriptions()]);

        // Set available plans and the user's current plan
        this.rows = plansRes.results.slice(1);
        this.objPlan = {
          subscription_id: subRes.id,
          pricing_id: subRes.pricing.id,
          plan_id: subRes.plan.id,
          plan_name: subRes.plan.name,
          amount: subRes.pricing.amount, // Current plan's pricing amount
        };
      } catch (err) {
        this.planError = true;
        this.message = err.response?.data?.errors?.join(", ") || "Failed to load plans.";
      }
    },

    // Get pricing ID based on the selected billing cycle
    getPricingId(pricingArray) {
      if (!Array.isArray(pricingArray)) {
        console.warn("Invalid pricing array:", pricingArray);
        return null;
      }
      const interval = this.billingCycle === "yearly" ? "year" : "month";
      const pricing = pricingArray.find((p) => p.interval === interval);
      return pricing ? pricing.id : null;
    },

    // Check if a plan is currently active
    isActive(pricingId) {
      return this.objPlan.pricing_id === pricingId;
    },

    // Determine if the selected plan is an upgrade
    isUpgrade(plan) {
      if (!plan || !Array.isArray(plan.pricing)) return false;
      const selectedPricing = plan.pricing.find((p) => p.interval === (this.billingCycle === "yearly" ? "year" : "month"));
      return selectedPricing && selectedPricing.amount > this.objPlan.amount;
    },

    // Determine if the selected plan is a downgrade
    isDowngrade(plan) {
      if (!plan || !Array.isArray(plan.pricing)) return false;
      const selectedPricing = plan.pricing.find((p) => p.interval === (this.billingCycle === "yearly" ? "year" : "month"));
      return selectedPricing && selectedPricing.amount < this.objPlan.amount;
    },

    // Handle plan selection and initiate upgrade/downgrade process
    selectPlan(plan) {
      if (!plan || !Array.isArray(plan.pricing)) {
        console.error("Invalid plan or missing pricing information:", plan);
        return;
      }

      // Find the selected pricing based on the current billing cycle
      const selectedPricing = plan.pricing.find((p) => p.interval === (this.billingCycle === "yearly" ? "year" : "month"));
      if (!selectedPricing) {
        console.warn("No pricing available for the selected interval:", this.billingCycle);
        return;
      }

      // Navigate to the checkout page with the selected pricing ID
      this.$router.push({ path: `/checkout/${selectedPricing.id}` });
    },

    // Check if a plan is currently active
    isActive(pricingId) {
      return this.objPlan.pricing_id === pricingId;
    },

    // Format monthly price for display
    getMonthlyPrice(plan) {
      const pricing = plan.pricing?.[0];
      return pricing ? `$${(pricing.amount / 100).toFixed(2)}/Mo` : "N/A";
    },

    // Format discounted monthly price based on yearly pricing
    getDiscountedMonthlyPrice(plan) {
      const pricing = plan.pricing?.find((p) => p.interval === "year");
      return pricing ? `$${((pricing.amount / 100) / 12).toFixed(2)}/Mo` : "N/A";
    },

    // Get the original monthly price for comparison
    getOriginalMonthlyPrice(plan) {
      const pricing = plan.pricing?.[0];
      return pricing ? `$${(pricing.amount / 100).toFixed(2)}/Mo` : "N/A";
    },

    // Logout method
    methodlogOut() {
      this.$http.get("/api/auth/logout/")
        .then(res => {
          this.$store.commit('REMOVE_USER_DATA');
          window.location.href = "/login";
        })
        .catch(err => {
          console.log(err);
        });
    },
  },
  created() {
    if (this.access_Role === 'owner') {
      this.planProduct();
    }
  }
};
</script>


<style scoped>
.bgcustom {
  background-color: #f5f5f5;
}

.card {
  background-color: #ffffff;
  border-radius: 17px;
}

.plan-card {
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}

.active {
  background-color: #ED3341;
  color: white;
}

.divider {
  border-top: 2px solid #949494FF;
  width: 50%;
}

blockquote {
  font-size: 1rem;
  border-left: 5px solid #f1b181;
  padding-left: 10px;
}

.text-danger {
  color: #ED3341;
}

.logo-width {
  width: 150px;
}

@media (min-width: 766px) {
  .lead-padding {
    padding-right: 100px;
    padding-left: 100px
  }
}

.img-above-yearly {
  height: 80px;
}


/* Active state styling for buttons */
.btn-group .btn.active {
  background-color: #ED3341;
  color: white;
  border-color: #F21A61;
}

/* Custom rounded buttons */
.btn-group .btn {
  padding: 0.5rem 1.5rem;
  border-width: 2px;
  border-color: #F21A61;
  color: #333333;
}

.btn-group .btn:hover {
  background-color: #ED3341;
  color: #FFFFFFFF;
}

/* Styling for the "Save 10%" label */
.save-label {
  font-size: 14px;
  color: #ED3341;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
}

.rounded-pill1 {
  border-top-left-radius: 17px;
  border-bottom-left-radius: 17px;
}

.rounded-pill2 {
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
}

/* Custom image styling */
.save-image {
  width: 70px;
  /* Custom width for the image */
  height: auto;
  /* Maintain aspect ratio */
  vertical-align: middle;
  margin-top: -40px;
}

.why-trust-section {
  background-color: #f8f9fa;
}

.why-trust-section h2 {
  font-size: 2.25rem;
}

.why-trust-section p.lead {
  font-size: 1.25rem;
  color: #6c757d;
}

.testimonial-card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  transition: transform 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-10px);
}

.testimonial-icon {
  color: #F21A61;
}

.testimonial-text {
  font-style: italic;
  font-size: 1.1rem;
  color: #343a40;
  margin-bottom: 20px;
}

.blockquote-footer {
  font-size: 0.9rem;
  color: #6c757d;
}

.testimonial-card footer strong {
  color: #343a40;
}

/* Plan Card Styling */
.plan-card {
  border-radius: 10px;
  padding: 1.5rem;
  background-color: white;
  transition: transform 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  border: 1px solid #ddd;
  min-height: 460px;
}

.plan-card.hovered {
  background-color: #FFF4F6FF;
}

.plan-card.selected {
  background-color: #F21A61;
  color: white;
}

.plan-card:hover {
  transform: translateY(-5px);
}

.choose-plan-btn {
  background-color: #F21A61;
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.choose-plan-btn:hover {
  background-color: #ED3341;
  transform: translateY(-3px);
}

.plan-features {
  margin-top: 20px !important;
}

.iconcolor {
  color: #F21A61;
  margin-right: 8px;
}






.pricing-section {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 134px;
  font-family: Poppins, sans-serif;
}

.pricing-container {
  display: flex;
  width: 100%;
  max-width: 646px;
  flex-direction: column;
  align-items: center;
}

.pricing-title {
  color: #0b2110;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: -0.32px;
  text-align: center;
}

.highlight {
  color: #349c4b;
}

.pricing-toggle {
  position: relative;
  display: flex;
  margin-top: 48px;
  flex-direction: column;
  font-weight: 600;
}

.toggle-container {
  border-radius: 27px;
  background-color: #fff;
  box-shadow: 0 10px 15px rgba(24, 59, 86, 0.2);
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  padding: 8px;
}

.toggle-button {
  border-radius: 16px;
  color: #626f65;
  padding: 4px 16px;
  background: none;
  border: none;
  cursor: pointer;
}

.toggle-button.active {
  background-color: #349c4b;
  color: #fff;
}

.savings-info {
  position: absolute;
  z-index: 0;
  display: flex;
  width: 156px;
  flex-direction: column;
  font-size: 12px;
  color: #7732bb;
  text-align: right;
  right: -135px;
  bottom: -21px;
}

.savings-icon {
  aspect-ratio: 2.58;
  object-fit: contain;
  width: 62px;
  margin-top: 6px;
}

.plans-container {
  margin-top: 88px;
  width: 100%;
  max-width: 1170px;
}

.recommended-label {
  align-self: center;
  border-radius: 4px 4px 0 0;
  background-color: #349c4b;
  box-shadow: 0 2px 5px rgba(24, 59, 86, 0.25);
  z-index: 10;
  width: 283px;
  color: #fff;
  padding: 4px 8px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.plans-grid {
  display: flex;
  width: 100%;
  gap: 12px;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .pricing-section {
    padding: 0 20px;
  }

  .pricing-title,
  .pricing-container,
  .plans-container {
    max-width: 100%;
  }

  .pricing-toggle {
    margin-top: 40px;
  }

  .plans-container {
    margin-top: 40px;
  }
}
</style>
