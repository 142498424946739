<template>
  <div class="form-group p-2">
    <!-- Text Input -->
    <div class="row pt-3">
      <div class="col">
        <textarea class="form-control" placeholder="Enter text here..." v-model="text" rows="3"></textarea>
      </div>
    </div>
    <!-- Font Family Selector -->
    <div class="row pt-3">
      <div class="col">
        <select class="form-select" v-model="family">
          <option v-for="font in fonts" :key="font" :value="font">
            {{ font }}
          </option>
        </select>
      </div>
    </div>
    <!-- Styling Buttons and Size Input -->
    <div class="row pt-3">
      <div class="col-6">
        <input type="number" class="form-control" placeholder="Font Size" v-model="size">
      </div>
      <div class="col-3 text-center">
        <button class="btn style-btn" @click="bold()"><i class="fa-solid fa-bold"></i></button>
      </div>
      <div class="col-3 text-center">
        <button class="btn style-btn" @click="italic()"><i class="fa-solid fa-italic"></i></button>
      </div>
    </div>
    <!-- Alignment Buttons -->
    <div class="row pt-3">
      <div class="col-4 text-center">
        <button class="btn style-btn" @click="left()"><i class="fa-solid fa-align-left"></i></button>
      </div>
      <div class="col-4 text-center">
        <button class="btn style-btn" @click="center()"><i class="fa-solid fa-align-center"></i></button>
      </div>
      <div class="col-4 text-center">
        <button class="btn style-btn" @click="right()"><i class="fa-solid fa-align-right"></i></button>
      </div>
    </div>
    <!-- Color Picker -->
    <div class="row pt-3">
      <div class="col">
        <label class="form-label">Color</label>
        <color-picker v-model:pure-color="fill" :inline="true" />
      </div>
    </div>
    <!-- Letter Spacing -->
    <div class="row pt-3">
      <div class="col">
        <label class="form-label">Letter Spacing</label>
        <input type="range" class="form-range" v-model="letterSpacing" min="0" max="10">
        <input type="number" v-model="letterSpacing" class="form-control mt-1">
      </div>
    </div>
    <!-- Word Spacing -->
    <div class="row pt-3">
      <div class="col">
        <label class="form-label">Word Spacing</label>
        <input type="range" class="form-range" v-model="wordSpacing" min="0" max="10">
        <input type="number" v-model="wordSpacing" class="form-control mt-1">
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, onMounted, ref, defineEmits } from 'vue';
import { ColorPicker } from 'vue3-colorpicker';
import WebFont from 'webfontloader';
import axios from 'axios';

// Define the component name
defineOptions({
  name: 'Text'
});

// Define props
const props = defineProps({
  element: {
    required: true
  }
});

// Define emits
const emit = defineEmits(['propertyChanged']);

const style = computed(() => getComputedStyle(props.element.node));

const fonts = ref([]);

const getStyle = (cssAttrName) => {
  const css = props.element.css(cssAttrName);
  if (css) return css;
  else return style.value[cssAttrName];
};

const rgbStringToHex = (rgbString) => {
  const match = rgbString.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);
  if (!match) return rgbString;
  const [r, g, b] = match.slice(1).map(Number);
  const toHex = (value) => {
    const hex = value.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

const loadFont = (font) => {
  return new Promise((resolve, reject) => {
    WebFont.load({
      google: {
        families: [font]
      },
      active: () => {
        resolve();
      },
      inactive: () => {
        reject(new Error(`Failed to load font: ${font}`));
      }
    });
  });
};

const text = computed({
  get() {
    return props.element ? props.element.text() : '';
  },
  set(value) {
    if (props.element) {
      const oldValue = props.element.text();
      props.element.text(value);
      emit('propertyChanged', {
        element: props.element,
        property: 'text',
        before: oldValue,
        after: value
      });
    }
  }
});

const family = computed({
  get() {
    return getStyle('font-family')
      ? getStyle('font-family').split(',')[0].replace(/['"]/g, '').trim()
      : fonts.value[0];
  },
  set(value) {
    const oldValue = getStyle('font-family');
    loadFont(value)
      .then(() => {
        props.element.css('font-family', value);
        emit('propertyChanged', {
          element: props.element,
          property: 'css:font-family',
          before: oldValue,
          after: value
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
});

const italic = () => {
  const oldValue = getStyle('font-style') || 'normal';
  const newValue = oldValue === 'italic' ? 'normal' : 'italic';
  props.element.css('font-style', newValue);
  emit('propertyChanged', {
    element: props.element,
    property: 'css:font-style',
    before: oldValue,
    after: newValue
  });
};

const bold = () => {
  const oldValue = getStyle('font-weight') || 'normal';
  const newValue = oldValue === 'bold' ? 'normal' : 'bold';
  props.element.css('font-weight', newValue);
  emit('propertyChanged', {
    element: props.element,
    property: 'css:font-weight',
    before: oldValue,
    after: newValue
  });
};

const size = computed({
  get() {
    return getStyle('font-size') ? getStyle('font-size').replace('px', '') : 14;
  },
  set(value) {
    const oldValue = getStyle('font-size');
    props.element.css('font-size', `${value}px`);
    emit('propertyChanged', {
      element: props.element,
      property: 'css:font-size',
      before: oldValue,
      after: `${value}px`
    });
  }
});

const left = () => {
  const oldValue = getStyle('text-align') || 'left';
  const newValue = 'left';
  props.element.css('text-align', newValue);
  emit('propertyChanged', {
    element: props.element,
    property: 'css:text-align',
    before: oldValue,
    after: newValue
  });
};

const center = () => {
  const oldValue = getStyle('text-align') || 'left';
  const newValue = 'center';
  props.element.css('text-align', newValue);
  emit('propertyChanged', {
    element: props.element,
    property: 'css:text-align',
    before: oldValue,
    after: newValue
  });
};

const right = () => {
  const oldValue = getStyle('text-align') || 'left';
  const newValue = 'right';
  props.element.css('text-align', newValue);
  emit('propertyChanged', {
    element: props.element,
    property: 'css:text-align',
    before: oldValue,
    after: newValue
  });
};

const fill = computed({
  get() {
    return getStyle('fill') ? rgbStringToHex(getStyle('fill')) : '#000000';
  },
  set(value) {
    const oldValue = getStyle('fill');
    props.element.css('fill', rgbStringToHex(value));
    emit('propertyChanged', {
      element: props.element,
      property: 'css:fill',
      before: oldValue,
      after: rgbStringToHex(value)
    });
  }
});

const letterSpacing = computed({
  get() {
    return getStyle('letter-spacing') ? getStyle('letter-spacing').replace('px', '') : 0;
  },
  set(value) {
    const oldValue = getStyle('letter-spacing');
    props.element.css('letter-spacing', `${value}px`);
    emit('propertyChanged', {
      element: props.element,
      property: 'css:letter-spacing',
      before: oldValue,
      after: `${value}px`
    });
  }
});

const wordSpacing = computed({
  get() {
    return getStyle('word-spacing') ? getStyle('word-spacing').replace('px', '') : 0;
  },
  set(value) {
    const oldValue = getStyle('word-spacing');
    props.element.css('word-spacing', `${value}px`);
    emit('propertyChanged', {
      element: props.element,
      property: 'css:word-spacing',
      before: oldValue,
      after: `${value}px`
    });
  }
});

onMounted(async () => {
  try {
    const response = await axios.get(
      'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDwotjdf_NtNBbM38PHOw6F_gDYWF8SMZk&sort=popularity'
    );
    fonts.value = response.data.items.map((item) => item.family);
  } catch (error) {
    console.error('Error fetching fonts:', error);
  }
});
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}

.form-label {
  font-weight: bold;
}

.style-btn {
  background-color: #e45c0d;
  /* Brand color */
  border: none;
  color: #ffffff;
}

.style-btn:hover {
  background-color: #f50975;
  /* Brand color */
}

.color-picker {
  width: 100%;
}

input[type="range"] {
  width: 100%;
}
</style>
