export default function globalErrorHandler(error) {
  // Check if the error contains a response (indicating an HTTP error)
  if (error?.response) {
    // If the status is 500, return a general server error message
    if (error.response.status === 500) {
      return ['500 Error: Please try again later'];
    }
    const data = error.response.data;
    let errors = [];

    // Process known error data structures
    if (typeof data === 'string') {
      errors.push(data);
    } else if (Array.isArray(data)) {
      errors = data;
    } else if (typeof data === 'object') {
      Object.keys(data).forEach(key => {
        const value = data[key];
        if (typeof value === 'string') {
          errors.push(value);
        } else if (Array.isArray(value)) {
          errors.push(...value);
        }
      });
    }
    return errors.length > 0 ? errors : ['An unexpected error occurred.'];
  }

  // Handle cases where error is a direct string or array
  if (typeof error === 'string') {
    return [error];
  } else if (Array.isArray(error)) {
    return error;
  }

  // Default error message if all else fails
  return ['Unexpected error, Please Try Again Later']; 
}