<template>
  <div class="container-fluid" v-if="!GET_USER">
    <div class="row p-3 btn-generate">
      <div class="col-12 col-sm-6 col-xxl-5">
        <h5>Soda Digital Marketing</h5>
      </div>
    </div>  
    <div class="row pt-2" v-if="this.step == 1" >
      <div class="col p-0">
        <div class="card form-color">
          <div class="card-body">
            <form>
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-12 col-sm-6 col-xxl-5">
                      <h4>Welcome Name!</h4>
                      <h5>All fields are required</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-sm-6 col-xxl-5">
                      How do you plan to use SodaMarketin ?
                      <select name="" id="" class="form-select">
                        <option value="">Select industry</option>
                        <option value="">Genegrate designs for my own brand name/s</option>
                        <option value="">Generate designs for my clients </option>
                      </select>
                    </div>
                    <div class="col-12 col-sm-6 col-xxl-5">
                      What is the size of your company ?
                      <select name="" id="" class="form-select">
                        <option value="">Select size</option>
                        <option value="">Only me </option>
                        <option value="">2 to 10 employee </option>
                        <option value="">100 to 500 employee</option>
                        <option value="">500+</option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="row py-4">
                    <!-- <div class="col-12 col-sm-6 col-xxl-5">
                      Workspace name
                      <input type="text" class="form-control" placeholder="Enter your company or organization name">
                    </div> -->
                    <div class="col-12 col-sm-6 col-xxl-5">
                      <label for="">Your website URL</label>
                      <input type="text" placeholder="Enter your company URL" class="form-control">
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col text-end pe-4">
                      <button type="button" class="btn btn-generate" @click.prevent="changeStep">Next Step</button>
                    </div>
                  </div>
                </div>
              </div>  
            </form>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row pt-2">
      <div class="col p-0">
        <div class="card form-color">
          <div class="card-body">
            <form>
              <div class="row py-1">
                <div class="col">
                  <div class="font-20">What is your job level ?</div>
                </div>
              </div>
              <div class="row py-2">
                <div class="col-12 col-sm-3 col-xxl-3">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="level" checked >
                      C level 
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" name="flexRadioDefault" type="radio" id="director">
                      Director
                    </label>
                  </div>
                </div>
                <div class="col-12 col-sm-3 col-xxl-3">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" name="flexRadioDefault" type="radio" id="individual">
                      individual contributor 
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" name="flexRadioDefault" type="radio"  id="freeLancer">
                      Freelancer
                    </label>
                  </div>
                </div>
                <div class="col-12 col-sm-3 col-xxl-3">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" name="flexRadioDefault" type="radio" id="other">
                      Other
                    </label>
                  </div>
                </div>
              </div>
              <div class="row py-2">
                <div class="col">
                  <div class="font-20">What is your role ?</div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-3 col-xxl-2">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" name="flexRadioDefault1" type="radio" id="Design" checked>
                      Graphic Design 
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" name="flexRadioDefault1" type="radio" id="creatives">
                      Content and creatives
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" name="flexRadioDefault1" type="radio" id="marketing">
                      Marketing
                    </label>
                  </div>
                </div>
                <div class="col-12 col-sm-3 col-xxl-2">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" name="flexRadioDefault1" type="radio" id="project">
                      Project Management
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" name="flexRadioDefault1" type="radio" id="sales">
                      Sales
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" name="flexRadioDefault1" type="radio" id="customer">
                      Customer
                    </label>
                  </div>
                </div>
                <div class="col-12 col-sm-3 col-xxl-2">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" name="flexRadioDefault1" type="radio" id="support">
                      Support
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" name="flexRadioDefault1" type="radio" id="others">
                      Other
                    </label>
                  </div>
                </div>
              </div>

              <hr>
              <div class="row">
                <div class="col-12 col-sm-10 col-xxl-8 text-end">
                  <button type="button" class="btn btn-generate" @click.prevent="changeStep">Previous</button>
                </div>
                <div class="col-12 col-sm-2 col-xxl-2 text-center">
                  <button type="submit" class="btn btn-generate">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters} from 'vuex';
export default {
  name: "plan",
  data() {
    return {
      step: 1,
    }
  },
  computed:{
    ...mapGetters(['GET_USER']),
  },
  methods:{
    changeStep(){
      if(this.step == 2){
        this.step = 1;
        console.log('iam here');
      }else {
        this.step= 2;
      }
    }
  }
}
</script>