<template>
  <div class="col-12 col-sm-4">
    <div class="row billing-bg">
      <div class="col py-3 px-0">
        <div class="text-center">
          <div class="text-color text-start">
            <div class="line-G" style="width: 14%;"></div>
            Available Credits
          </div>
          <div class="billing-color font-70">
            {{ GET_BASE_SUBSCRIPTION?.credits?.remaining || 0 }}
          </div>
          <div>
            <button class="billing-button" data-bs-toggle="modal"
                    data-bs-target="#exampleModal">
              Buy Credits
            </button>
          </div>
        </div>
      </div>
      <div class="col py-3 px-0">
        <div class="credits-border">
          <div class="font-14">From Your Subscription</div>
          <div class="font-26">
            {{ GET_BASE_SUBSCRIPTION?.credits?.remaining || 0 }} / {{ GET_BASE_SUBSCRIPTION?.credits?.total || 0 }}
          </div>
          <div class="text-color font-12">Valid till : {{ GET_BASE_SUBSCRIPTION?.current_period_end }}</div>
          <hr class="dropdown-divider">
          <div>Add On Credits</div>
          <div v-if="GET_BASE_SUBSCRIPTION?.credits_addons_only?.remaining" class="font-26 text-start">
            {{ GET_BASE_SUBSCRIPTION?.credits_addons_only?.remaining || 0 }}
          </div>
          <div v-else class="font-bold ps-2 font-18">0</div>
          <div class="text-color font-12">Valid as long as your Subscription is active</div>
        </div>
      </div>
    </div>
    <div class="row pt-3 padding_T">
      <div class="text-color font-20 billing-bg py-2 text-start px-0 pt-3">
        <div class="line-G" style="width:6%;"></div>
        Payment Method
      </div>
      <div class="col billing-bg pb-4">
        <div class="payment-color change_color font-14 text-end">
          <a>
            Update Payment Details
          </a>
        </div>
      </div>
    </div>
  </div>

  <!--  Modals-->
  <!--begin:modal-BuyCredits-->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="Credits-ModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="row pt-3">
          <div class="col-10 col-sm-10">
            <h5 class="modal-title text-color" id="Credits-ModalLabel">
              <div class="line-G" style="width: 6%;"></div>
              Choose Your Credits Bundle
            </h5>
          </div>
          <div class="col-2 col-sm-2 text-center">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
        </div>
        <div v-if="errors" class="alert alert-danger mx-4 text-center p-1 br-radius">
          <span v-for="(error,index) in errors" :key="index" class="text-color">{{ error }}</span>
        </div>
        <div class="modal-body">
          <div class="pb-2">Select</div>
          <div class="row">
            <div class="col-12 col-sm-12 py-1" v-for="(credit,index) in creditAddon?.pricing" :key="index">
              <div class="form-control d-flex billing-border">
                <label class="mouse">
                  <input class="form-check-input payment-color billing-border mx-2" :value="credit?.id"
                         v-model="balance" type="radio" name="flexRadioDefault">
                  Credits {{ credit?.features?.credits }}
                </label>
                <div class="payment-color ms-auto">USD {{ credit?.amount / 100 }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
          <div class="text-center" v-if="isLoading">
            <loader/>
          </div>
          <button v-else type="button" class="btn billing-button w-75 m-auto" @click.prevent="attachCredits">Pay
          </button>
          <div class="m-auto pt-2">Your Saved Payment Method will be charged Immediately</div>
        </div>
      </div>
    </div>
  </div>
  <!--end:modal-BuyCredits-->
</template>
<script>

import {attachAddon, retrieveBaseSubscriptions, retrieveProductByName} from "@/apis";
import globalErrorHandler from "@/globalErrorHandler";
import {mapGetters} from "vuex";

export default {
  name: 'Subscription',

  data() {
    return {
      can_attach_credits: this.GET_BASE_SUBSCRIPTION?.plan != 'free',
      subscription: null,
      errors: null,
      creditAddon: null,
      isLoading: false,
      balance: null
    };
    
  },
  computed: {
    ...mapGetters(['GET_BASE_SUBSCRIPTION'])
  },
  mounted() {
    this.retrieveCredits();
  },
  methods: {
    async retrieveCredits() {
      this.isLoading = true;
      try {
        this.isLoading = false;
        this.creditAddon = await retrieveProductByName("Credits")
      } catch (err) {
        this.isLoading = false;
        this.errors = globalErrorHandler(err.data);
        window.scroll(0, 0);
      }
    },
    async attachCredits() {
      this.isLoading = true;
      try {
        this.isLoading = true;
        await attachAddon({
          pricing: this.balance
        })
        window.location.reload();
      } catch (err) {
        this.isLoading = false;
        this.errors = globalErrorHandler(err.data);
        window.scroll(0, 0);
      }
    },
  }
};
</script>

<style scoped>
button.btn.billing-button.disabled {
    color: #f9419e;
}


</style>