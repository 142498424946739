<template>
  <div class="col-sm-12">
    <div class="border border-secondary border-opacity-10 rounded p-3">
      <h6 class="mb-3">
        
        {{ subscription.name }}

        <span class="badge-primary">{{ subscription.status }}</span>
      
      </h6>
      <p class="text-muted">Valid till: {{ subscription.validTill }}</p>
      <ul class="list-unstyled subscription-details">
        <li v-for="(detail, index) in subscription.details" :key="index">
          <i class="fa fa-check me-2"></i>{{ detail }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subscription: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
.subscription-details {
  padding-left: 0;
}
/* Primary badge styling */
.badge-primary {
  background-color: #4caf50;
  color: white;
  padding: 0.2rem 0.5rem;
  border-radius: 8px;
  font-size: 0.75rem;
}

</style>
