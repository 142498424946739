<template>
  <nav id="sidebarMenu" class="collapse sidebar d-lg-flex fixed-top mt-5 pt-5 justify-content-center ">
    <div class="menu-items">
      <!-- <div>
        <button class="btn-toggle" @click.prevent="openMenu()">
          <i id="buttonbar" class="fa-solid fa-bars sidebar-toggle"></i>
        </button>
      </div> -->
      <div class="list-group list-group-flush left-menu mt-4">
        <div class="button-project  links py-2">
          <router-link to="/"> 
            <img src="/assets/images/Vector-3.png" class="w-bubble"  alt="">
            <span class="link-name">DESIGNS</span>
          </router-link>
          </div>
        <div class=" pb-2 links">
          <router-link to="/library" :class="('library'.indexOf(GET_ROUTE) !=-1)? 'active': '' ">
            <i class="fa fa-home icon-menu"></i>
            <span class="link-name">Library</span> 
          </router-link>
        </div>
        <div class=" pb-2 links">
          <router-link to="/brandview" :class="(['brandview','cbrand'].indexOf(GET_ROUTE) != -1)? 'active' : '' " >
            <i class="fa fa-braille icon-menu"></i>
            <span class="link-name">Brands</span> 
          </router-link>
        </div>
        <div class="text-center pb-2 links">
          <router-link to="/users" :class="(['users'].indexOf(GET_ROUTE) != -1)? 'active' : '' ">
            <i class="fa fa-users icon-menu"></i>
            <span class="link-name">Users</span>
          </router-link>
        </div>
        <div v-if="access_Role == 'owner'" class="text-center pb-2 links">
          <router-link to="/billing" :class="(['billing'].indexOf(GET_ROUTE) != -1)? 'active' : '' ">
            <i class="fa fa-money-bill-1 icon-menu"></i>
            <span class="link-name">Billing</span>
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapGetters } from 'vuex'
let menubar = document.querySelector('#sidebarMenu');

export default {
  data(){
    return{
      access_Role: localStorage.getItem('role'),
    }
  },
  computed:{
    ...mapGetters(['GET_ROUTE']),
  },

  methods:{
    openMenu(){
      document.getElementById('sidebarMenu').classList.toggle('close-menu');
    }
  }
}
</script>
